import "./DottedLoaderBox.scss";
import PropTypes, { InferProps } from "prop-types";
import Box from "@mui/material/Box";
import DottedLoader from "../DottedLoader/DottedLoader";

const DottedLoaderBoxPropTypes = {
  sx: PropTypes.object
}

const DottedLoaderBox = (props: InferProps<typeof DottedLoaderBoxPropTypes>) => {
  return (
    <Box
      sx={{
        height: "100px",
        width: "100px",
        backgroundColor: 'bg-secondary.main',
        display: "grid",
        placeItems: "center",
        borderRadius: 3,
        ...props.sx
      }}>
      <DottedLoader />
    </Box>
  );
};

DottedLoaderBox.propTypes = DottedLoaderBoxPropTypes;

export default DottedLoaderBox;

import React from 'react';
import ArticleListItem from "../../components/ArticleListItem/ArticleListItem";
import DirCard from "../../components/DirCard/DirCard";
import HeadingContainer from "../../components/HeadingContainer/HeadingContainer";
import CardList from "../../components/CardList/CardList";
import SearchBar from "../../components/SearchBar/SearchBar";
import CurvedListItemContainer from '../../components/CurvedListItemContainer/CurvedListItemContainer';
import ListItem from '../../components/ListItem/ListItem';
import SearchIcon from "../../assets/search-small.svg";
import ClearIcon from "../../assets/clear.svg";
import ChevronRight from "../../assets/chevron-right.svg";
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import "./Home.scss";
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { getDirectories } from '../../services/directories'
import { getRecommendedArticles } from '../../services/articles';
import { getOngoingChat } from '../../services/chat';
import routes from '../../utils/routes';
import { getUserDetails } from '../../services/getUserDetails';
import { ApiResponse } from '../../types/auth';
import { withSnackBar, getSnackbarArgs, getDomain } from '../../utils/utils';
import { sendScribeEvent } from "../../utils/scribeHelper";
import i18n from '../../i18n/config';
import { DOMAIN_BUSINESS } from '../../constants/domain';
// import { withTranslation } from "react-i18next"

type OngoingChat = {
    agentName?: string;
    chatId?: string;
}

interface RecommendedArticle {
    pageId: number,
    title: string,
}

interface ComponentState {
    inputText: string,
    updateInputText: (event: any) => void,
    directories: Array<any>,
    recommendedArticles: Array<RecommendedArticle>,
    userName: string,
    ongoingChat: OngoingChat,
    // loading: {
    //     directories: boolean,
    //     recommendedArticles: boolean,
    //     userName: boolean,
    //     ongoingChat: boolean
    // }
}

class Home extends React.Component<any, ComponentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            inputText: "",
            updateInputText: (value: string) => { this.setState({ inputText: value }) },
            directories: [],
            recommendedArticles: [],
            userName: "",
            ongoingChat: {},
            // loading: {
            //     directories: false,
            //     recommendedArticles: false,
            //     userName: false,
            //     ongoingChat: false,
            // }
        }
    }

    async componentDidMount() {
        sendScribeEvent("help_center_home_access_show")
        const parentGrid = document.getElementById("parent-grid")
        if (parentGrid) {
            parentGrid.style.overflow = "hidden"
        }

        // let apiResponses = (await this.settleAll([
        //     this.getDirectoryList(),
        //     this.getRecommendedArticleList(),
        //     this.getUserName(),
        //     this.getOngoingCustomerChats()
        // ])).map((response: any) => response.value)
        // let getDirectoryListResponse = await this.getDirectoryList()
        // let getRecommendedArticleListResponse = await this.getRecommendedArticleList()
        // let getUserNameResponse = await this.getUserName()
        // let getOngoingCustomerChatsResponse = await this.getOngoingCustomerChats()
        this.getRecommendedArticleList()
        this.getOngoingCustomerChats()
        this.getDirectoryList()
        this.getUserName()
        // let apiResponses = [
        //     getDirectoryListResponse,
        //     getRecommendedArticleListResponse,
        //     getUserNameResponse,
        //     getOngoingCustomerChatsResponse
        // ]

        // let errorOccured = apiResponses.some((ele) => ele.error)
        // if (errorOccured)
        //     this.props.openSnackbar(getSnackbarArgs({
        //         onClose: this.props.closeSnackbar,
        //         uniqueKey: "home-page",
        //     }))

        // let [
        //     getDirectoryListResponse,
        //     getRecommendedArticleListResponse,
        //     getUserNameResponse,
        //     getOngoingCustomerChatsResponse
        // ] = apiResponses

        // this.setState(prevState => {
        //     return {
        //         ...prevState,
        //         directories: getDirectoryListResponse.res,
        //         recommendedArticles: getRecommendedArticleListResponse.res,
        //         userName: getUserNameResponse.res,
        //         ongoingChat: getOngoingCustomerChatsResponse.res,
        //         loading: false
        //     }
        // })
    }

    componentWillUnmount() {
        const parentGrid = document.getElementById("parent-grid")
        if (parentGrid) {
            parentGrid.style.overflow = "unset"
        }
    }

    openErrorSnackbar = () => {
        this.props.openSnackbar(getSnackbarArgs({
            onClose: this.props.closeSnackbar,
            uniqueKey: "home-page",
        }))
    }

    async getRecommendedArticleList() {
        try {
            let res = await getRecommendedArticles()
            // return { res: res.articles as Array<RecommendedArticle> || [] } as ApiResponse
            this.setState(prevState => {
                return {
                    ...prevState,
                    recommendedArticles: res.articles || [],
                }
            })
        } catch (error) {
            console.error(error)
            this.openErrorSnackbar()
            return { res: [], error }
        }
    }

    async getOngoingCustomerChats() {
        try {
            let res = await getOngoingChat()
            // return { res: res as OngoingChat } as ApiResponse
            this.setState(prevState => {
                return {
                    ...prevState,
                    ongoingChat: res,
                }
            })
        } catch (error) {
            console.error(error);
            this.openErrorSnackbar() 
            return { res: {} as OngoingChat, error } as ApiResponse
        }
    }

    async getDirectoryList() {
        try {
            const res = await getDirectories()
            // return { res: res["directory"] as Array<string> || [] } as ApiResponse
            this.setState(prevState => {
                return {
                    ...prevState,
                    directories: res.directory || [],
                }
            })
        } catch (error) {
            console.error(error);
            this.openErrorSnackbar()
            return { res: [], error } as ApiResponse
        }
    }

    async getUserName() {
        try {
            const res = await getUserDetails()
            // return { res: res.userName || "" } as ApiResponse
            this.setState(prevState => {
                return {
                    ...prevState,
                    userName: res.userName || "",
                }
            })
        } catch (error) {
            console.error(error)
            this.openErrorSnackbar()
            return { res: "", error } as ApiResponse
        }
    }

    render() {
        return (
            <div className="home">
                <Box className={`gxs-gradient-background${getDomain() === DOMAIN_BUSINESS ? '--business' : ''}`} tabIndex={-1}></Box>
                {!this.state.userName ?
                    <Skeleton className="loader"
                        sx={{
                            width: "170px",
                            height: "60px"
                        }} />
                    :
                    <Typography variant="heading-04" display="block" mb={6}>
                        {/* Hi <Typography sx={{ textTransform: "capitalize" }} color="text-link.main" variant="heading-04" display="inline-block">{this.state.userName}
                        </Typography>, */}
                        <Typography display="block" variant="heading-04">
                            {/* How can we help? */}
                            {i18n.t("helpcentre_home_greeting")}
                        </Typography>
                    </Typography>
                }


                {
                    !this.state.userName ?
                        <Skeleton className="loader"
                            // height={43}
                            width={"100%"}
                            variant="rectangular"
                        >
                            <Link className="no-text-decoration" to={routes.searchPage.path}>
                                <Box sx={{ mb: 6 }}>
                                    <SearchBar
                                        placeholder={i18n.t("helpcentre_home_placeholder_search_bar")}
                                        primaryIcon={SearchIcon}
                                        secondaryIcon={ClearIcon}
                                        value={this.state.inputText}
                                        onChange={this.state.updateInputText}
                                    />
                                </Box>
                            </Link>
                        </Skeleton>
                        :
                        (<>
                            <Link className="no-text-decoration" to={routes.searchPage.path} onClick={() => { sendScribeEvent("help_center_search_tap") }}>
                                <Box sx={{ mb: 6 }}>
                                    <SearchBar
                                        placeholder={i18n.t("helpcentre_home_placeholder_search_bar")}
                                        primaryIcon={SearchIcon}
                                        secondaryIcon={ClearIcon}
                                        value={this.state.inputText}
                                        onChange={this.state.updateInputText}
                                    />
                                </Box>
                            </Link>
                        </>
                        )
                }

                {
                    this.state.ongoingChat.agentName &&
                    <HeadingContainer text3={i18n.t("helpcentre_home_on_going_chat_title")}>
                        <Box>
                            <CurvedListItemContainer>
                                <Link to={routes.chat.path} className='no-text-decoration' onClick={() => { sendScribeEvent("help_center_inprogress_chat_tap") }}>
                                    <ListItem
                                        primaryText={
                                            <Typography
                                                variant="body-02"
                                                sx={{ color: "text-primary.main" }}>
                                                {i18n.t("helpcentre_home_on_going_chat_primary_chat")} {this.state.ongoingChat.agentName}
                                            </Typography>
                                        }
                                        secondaryText={
                                            <Typography
                                                variant="body-03"
                                                sx={{ color: "text-tertiary.main" }}>
                                                {i18n.t("helpcentre_home_on_going_chat_secondary_chat")} {this.state.ongoingChat.chatId}
                                            </Typography>
                                        }
                                        tertiaryText={
                                            <Typography mt={3} variant="metadata-01-semibold" sx={{ color: "text-primary.main" }}>
                                                <Chip
                                                    label={i18n.t("helpcentre_home_on_going_chat_tertiary_chat")}
                                                    color="brand-tertiary"
                                                    size="small"
                                                    sx={{
                                                        borderRadius: 1,
                                                        p: 2,
                                                        '& .MuiChip-label': { px: 0 }
                                                    }} />
                                            </Typography>
                                        }
                                        secondaryIcon={
                                            <IconButton><img src={ChevronRight} alt="" /></IconButton>
                                        }
                                        isCurved={false}
                                    />
                                </Link>
                            </CurvedListItemContainer>
                        </Box>
                    </HeadingContainer>
                }

                {
                    this.state.recommendedArticles.length <= 0 ?
                        <HeadingContainer>
                            <Skeleton className="loader"
                                width={157}
                                height={43} />
                            <Skeleton
                                height={212}
                                className="loader"
                                variant="rectangular"
                            />
                        </HeadingContainer>
                        :
                        <HeadingContainer text3={i18n.t("helpcentre_home_recommended_articles_title")}>
                            <Box>
                                <CurvedListItemContainer >
                                    {this.state.recommendedArticles.map((article: RecommendedArticle, index: number) => {
                                        return (
                                            <Link to={`/article/${article.pageId}`}
                                                key={'article-list-item-' + index}
                                                state={{from: "recommended-articles"}}
                                                className='no-text-decoration' onClick={() => { sendScribeEvent("help_center_article_tap", { "article_name": article.title, "article_placement_order": index + 1 }) }}>
                                                <ArticleListItem text={article.title} icon={ChevronRight} isCurved={false} />
                                            </Link>
                                        )
                                    })}
                                </CurvedListItemContainer>
                            </Box>
                        </HeadingContainer>
                }


                {
                    this.state.directories.length <= 0 ?
                        <HeadingContainer>
                            <Skeleton className="loader"
                                width={157}
                                height={43} />
                            <Skeleton
                                className="loader"
                                height={212}
                                variant="rectangular"
                            />
                        </HeadingContainer>
                        :
                        <HeadingContainer text3={i18n.t("helpcentre_home_directory_title")}>
                            <CardList>
                                {this.state.directories.map((item: any, index: number) => {
                                    return (
                                        <Grid item xs={8} key={index}>
                                            <Link className='no-text-decoration' to={`/dir-list/${item.id}`} state={{from: "home-directory"}} onClick={() => {
                                                sendScribeEvent("help_center_directory_tap", { directory_name: item.title })
                                            }}>
                                                <DirCard item={item} />
                                            </Link>
                                        </Grid>
                                    )
                                })}
                            </CardList>
                        </HeadingContainer>
                }

                {
                    !this.state.userName ?

                        <HeadingContainer>
                            <Skeleton className="loader"
                                width={157}
                                height={43} />
                            <Skeleton
                                className="loader"
                                height={212}
                                variant="rectangular"
                            />
                        </HeadingContainer>
                        :
                        <HeadingContainer text3={i18n.t("helpcentre_home_more_help_title")}>
                            <Link to={routes.getHelp.path} className='no-text-decoration' onClick={() => { sendScribeEvent("help_center_getmorehelp_tap") }}>
                                <ArticleListItem text={i18n.t("helpcentre_home_more_help_get_more_help")} icon={ChevronRight} />
                            </Link>
                        </HeadingContainer>
                }

            </div>
        )
    }
}

export default withSnackBar(Home);

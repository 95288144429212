import Typography from '@mui/material/Typography';
import BaseCard from '../BaseCard/BaseCard'
import './DirCard.scss'


const DirCard = (props: any) => {
    return (
        <BaseCard>
            <div className="dir-cards">
                <div className="imgHolder">
                    <img src={props.item.imageUrl} alt="" />
                </div>
                <Typography className='description' color="text-primary.main" variant="body-02">{props.item.title}</Typography>
            </div>
        </BaseCard>
    )
}

export default DirCard;


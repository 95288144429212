import { getUserDetails } from "../services/getUserDetails"
import { ApiResponse } from "../types/auth"

export type UserStatus = {
    isEmailVerified: boolean
    isOnboarded: boolean
    isFirstFundDone: boolean
    chatbotEnabled: boolean
    isChatbotAgreementMissing: boolean
}
const getUserStatus = async (): Promise<ApiResponse<UserStatus>> => {
    try {
        const res = await getUserDetails({ cache: false })
        return { res: { 
            isEmailVerified: res.isEmailVerified,
            isOnboarded:     res.isOnboarded,
            isFirstFundDone: res.isFirstFundDone,
            chatbotEnabled: res.chatbotEnabled,
            isChatbotAgreementMissing: res.isChatbotAgreementMissing
        }}
    } catch (error) {
        console.error(error)
        return { res: "", error } as ApiResponse
    }
}

export {
    getUserStatus
}
import httpClient from './base'; //change this if your axios isnstance is elsewhere

import { cardTransactionDetailsReqType, updateCardStatusReqType, cardDetailsReqType } from '../types/cards';

const cardTransactionDetails = async (body: cardTransactionDetailsReqType) => {
    const res = await httpClient.post("/v1/card-transaction-info", body)
    return res.data
}

const updateCardStatus = async (body: updateCardStatusReqType) => {
    const res = await httpClient.put("/v1/update-card-status", body)
    return res.data
}

const cardDetails =  async (body: cardDetailsReqType) => {
    const res = await httpClient.post("/v1/get-card-status", body)
    return res.data
}

export { cardTransactionDetails, updateCardStatus, cardDetails } 
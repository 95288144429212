const animateMessageToChatOptions = (parentElement: HTMLDivElement) => {
    console.log("chat not active, should go back to unset", parentElement);
    setTimeout(() => {
        parentElement.style.height = `unset`
        parentElement.style.width = `unset`
    }, 1);
}

const animateChatOptionsToMessage = (parentElement: HTMLDivElement) => {
    parentElement.style.height = `${parentElement?.getBoundingClientRect().height}px`
    parentElement.style.width = `${parentElement?.getBoundingClientRect().width}px`
    parentElement.style.transition = "all ease-in-out 500ms"
    setTimeout(() => {
        parentElement.style.height = "54px"
        parentElement.style.width = "150px"
    }, 1)
}

export { animateMessageToChatOptions, animateChatOptionsToMessage }
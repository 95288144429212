import httpClient from './base'; //change this if your axios instance is elsewhere

const getArticles = async (params: object) => {
    const res = await httpClient.get("/v1/search-article", { params: params })
    return res.data
}

const getRecommendedArticles = async () => {
    const res = await httpClient.get("/v1/recommended-article")
    return res.data
}

const viewArticle = async (params: object) => {
    const res = await httpClient.get("/v1/view-article", { params: params })
    return res.data
}



export { getArticles, getRecommendedArticles, viewArticle }

import httpClient from './base'; //change this if your axios instance is elsewhere
import {cardTransactioDisputeForm} from "../static-data-stubs/contact-form"; //change this if your axios instance is elsewhere
import {string} from "prop-types";

interface contactUsFormData {
    issueType: string
    attachments: attachmentData[]
    details?: IssueDetails
}

interface IssueDetails {
    description?: string
    transactionID?: string
    transactionAuthorized?: string
    reasonForDispute?: string
    transactionFraudulent?: string
    disputeField?: Array<disputeFieldValue>
}

type disputeFieldValue = {
    values: Array<string>,
}

interface attachmentData {
    fileName: string,
    fileExtension: string,
    content: string
}

const contactUs = async (data: contactUsFormData) => {
    const res = await httpClient.post("/v2/contact-us-form", data)
    return res.data
}

const getIssueTypes = async () => {
    const res = await httpClient.get("/v1/contact-us-form-issue-types/contactUsForm")
    return res.data
}

const getFormConfig = async (issueType: string) => {
    const res = await httpClient.get(`/v1/contact-us-form-config/${issueType}`)
    return res.data
}

export { contactUs, getIssueTypes, getFormConfig }
export type { contactUsFormData, attachmentData, IssueDetails }

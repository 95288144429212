import React, { createContext, useState, useCallback } from "react";
import Snackbar from "../../components/Snackbar/Snackbar";

export const SnackbarContext = createContext({
    openSnackbar: (ele: any) => { },
    closeSnackbar: () => { }
})

export function SnackbarProvider({ children }: any) {

    const [state, setState] = useState({
        vertical: 'top',
        horizontal: 'center',
        open: false,
        onClose: () => { },
        uniqueKey: 'unique-key',
        autoHideDuration: 3000,
        variant: "error",
        primaryIcon: "",
        primaryMessage: "",
        secondaryMessage: "",
        onCloseActionContent: "",
    })

    const openSnackbar = useCallback(
        (ele: any) => {
            setState(prevState => {
                return {
                    ...prevState,
                    open: true,
                    ...ele
                }
            })
        },
        [setState]
    )

    const closeSnackbar = useCallback(
        () => {
            setState(prevState => {
                return {
                    ...prevState,
                    open: false,
                }
            })
        },
        [setState],
    )

    // const value = useMemo(() => ({ openSnackbar, closeSnackbar }), [openSnackbar, closeSnackbar])
    const value = {
        openSnackbar: openSnackbar,
        closeSnackbar: closeSnackbar,
    }

    return (
        <SnackbarContext.Provider value={value}>
            {children}
            <Snackbar {...state} />
        </SnackbarContext.Provider>
    )
}
import React from 'react';
import "./Article.scss";
import PropTypes, { InferProps } from "prop-types";
import HeadingContainer from '../../components/HeadingContainer/HeadingContainer';
import ArticleNotFound from '../../components/ArticleNotFound/ArticleNotFound';
import { Box } from '@mui/system';
import ListItem from '../../components/ListItem/ListItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import ChevronRight from "../../assets/chevron-right.svg";
import { Link, useLocation, useParams } from 'react-router-dom';
import theme from '../../theme';
import { viewArticle } from '../../services/articles';
import { getSnackbarArgs } from '../../utils/utils';
import routes from '../../utils/routes';
import { ApiResponse } from '../../types/auth';
import { openLink } from '../../utils/mobileComms';
import httpStatus from '../../utils/httpStatusCodes';
import DOMPurify from 'dompurify';
import { sendScribeEvent } from "../../utils/scribeHelper";
import useSnackbars from '../../context/Snackbar/Consumer';
import {useTranslation} from "react-i18next";
import i18n from "../../i18n/config";

interface ViewArticle {
    title: string,
    content: string,
}

interface ComponentState {
    article: ViewArticle,
    notFound: boolean,
    loading: boolean,
}

const ArticlePropTypes = {
    params: PropTypes.any.isRequired,
    openSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    location: PropTypes.any.isRequired,
    t: PropTypes.any.isRequired
}

class Article extends React.Component<InferProps<typeof ArticlePropTypes>, ComponentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            article: {
                title: "",
                content: ""
            },
            notFound: false,
            loading: false,
        }
    }

    async componentDidMount() {
        this.setState({ loading: true })
        const pageId = this.props.params.id
        const state = this.props.location.state
        let getArticleResponse = await this.getArticle(pageId, state)
        if (getArticleResponse.error?.response?.status === httpStatus.NOT_FOUND)
            this.setState({ notFound: true })
        else if (getArticleResponse.error)
            this.props.openSnackbar(getSnackbarArgs({
                onClose: this.props.closeSnackbar,
                uniqueKey: "article-display",
            }))
        else
            this.setState({
                article: getArticleResponse.res
            })

        this.setState({ loading: false })
    }

    async getArticle(pageId: number, state: any) {
        try {
            let res = await viewArticle({ pageId: pageId,  isDirectView: state.isFirstPage})
            return { res: res as ViewArticle } as ApiResponse
        } catch (error) {
            console.error(error)
            return { res: {} as ViewArticle, error } as ApiResponse
        }
    }

    createMarkup(content: string) {
        return { __html: DOMPurify.sanitize(content) }
    }

    findIfLinkIsParent = (event: React.MouseEvent<HTMLElement>) => {
        // console.log(this, "click detected");

        let node = event.target as HTMLElement | null
        // console.log(node?.closest('A'), "node Being recieved");
        const closestLink = node?.closest('A')

        if (closestLink) {
            event.preventDefault()
            if (closestLink.getAttribute('href')) {
                openLink(closestLink.getAttribute('href') as string)
            }
        }

        // Manual traversal of the tree
        // do {
        //     if (node?.nodeName === 'A') {
        // event.preventDefault()

        //         // return node
        //         console.log("found!", node);

        //     }
        //     node = node?.parentElement || null
        // }while (node!==null)
        return null
    }

    // articleArea:HTMLElement|null = document.getElementById("articleArea")
    // articleArea.addEventListener('click', ()=>{})

    render() {
        const { t } = this.props
        if (this.state.notFound)
            return <ArticleNotFound />
        else if (this.state.loading)
            return (
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={80} />
                </Box>
            )

        return (
            <HeadingContainer text4={this.state.article.title}>
                <Box
                    onClick={(event: React.MouseEvent<HTMLElement>) => { this.findIfLinkIsParent(event) }} id='articleArea'
                    component={"section"} sx={{
                        color: 'text-primary.main',
                        fontFamily: theme.typography.fontFamily,
                        typography: 'body-03',
                        "a": {
                            color: 'text-link.main',
                        },
                        'h2': {
                            typography: 'callout-01',
                        },
                        'h3': {
                            typography: 'heading-05',
                        },
                        'h4': {
                            typography: 'callout-02',
                            color: 'text-tertiary.main',
                        },
                        'h5': {
                            typography: 'callout-03',
                            color: 'text-tertiary.main',
                        },
                    }}
                    dangerouslySetInnerHTML={this.createMarkup(this.state.article.content || '')}>
                </Box>
                <div className='need-help'>
                    <HeadingContainer text3={t("helpcentre_article_page_need_more_help")}>
                        <Link to={routes.getHelp.path} className='no-text-decoration' onClick={() => {sendScribeEvent("help_article_getmorehelp_tap", {"article_name":this.state.article.title})}}>
                            <ListItem
                                primaryText={
                                    <Typography variant="body-02" sx={{ color: "text-primary.main" }}>{t("helpcentre_article_page_get_in_touch")}</Typography>
                                }
                                secondaryIcon={
                                    <IconButton><img src={ChevronRight} alt="" /></IconButton>
                                }
                            />
                        </Link>

                    </HeadingContainer>
                </div>
            </HeadingContainer >
        )
    }
}

// export default withSnackBar(withRouter(Article));
const MainArticle = (props: any) => {
    const location = useLocation()
    const params = useParams()
    const { openSnackbar, closeSnackbar } = useSnackbars()
    const { t } = useTranslation()
    return <Article location={location} params={params} openSnackbar={openSnackbar} closeSnackbar={closeSnackbar} t={t} {...props} />
}

export default MainArticle
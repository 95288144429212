import Grid from '@mui/material/Grid'
import './CardList.scss'

const CardList = (props: any) => {
    return (
        <Grid spacing={3} container columns={16}>
            {props.children}
        </Grid>
    )
}

export default CardList
import { Box, Typography } from "@mui/material";
import "./FileUploader.scss";
import PropTypes, { InferProps } from "prop-types";
import { FormEvent, useRef, useState } from "react";
import { readURL } from "../../utils/dynamicForm";
import { fileData } from "../../types/fileUpload";
import { uploadDocument, checkFileStatus, getDocuments } from "../../services/documents";
import DottedLoader from "../DottedLoader/DottedLoader";
import { useInterval } from "../../utils/utils";

const FileUploaderPropTypes = {}

const FileUploader = (props: InferProps<typeof FileUploaderPropTypes>) => {

  const [file, setFile] = useState({} as fileData)
  const [fileUploadLoader, setFileUploadLoader] = useState(false)
  const fileUplodInfo = useRef({} as any)
  const fileUploadTime = useRef({
    fileUpload:{
      startTime: 0,
      endTime: 0
    },
    fileScanning:{
      startTime: 0,
      endTime: 0
    }
  })
  const [fileStatus, setFileStatus] = useState('Not started')

  const handleFileChange = async (ev: any) => {
    if (!ev.target.files) return
    console.log(ev.target.files);
    const file = ev.target.files[0]
    const splitFileName = file.name.split('.')
    const extensionLocation = splitFileName.length - 1
    const fileExtension = splitFileName[extensionLocation]
    const fileObj: fileData = {
      fileName: file.name,
      fileContent: (await readURL(file) as string).split(',')[1],
      fileExtension,
      fileType: "contactUsForm",
    }
    setFile(fileObj)
  }

  const handleUploadFile = async (ev: FormEvent) => {
    ev.preventDefault()
    console.log("uploading");
    setFileStatus("Uploading")
    setFileUploadLoader(true)
    try {
      fileUploadTime.current.fileUpload.startTime = Date.now()
      const res = await uploadDocument(file)
      fileUploadTime.current.fileUpload.endTime = Date.now()
      fileUploadTime.current.fileScanning.startTime = Date.now()
      if (res) {
        setFileStatus("Uploaded")
        fileUplodInfo.current = res
        console.log(fileUplodInfo, "id being set");
      }
      fetchFileStatus(res.id)
      setFileStatus("scanning")
    } catch (error) {
      fileUploadTime.current.fileUpload.startTime = 0
      setFileStatus("Upload failed")
    }
    setFileUploadLoader(false)
    //if success start pinging the check endpoint until you get a valid response or time out 
  }

  const fetchFileStatus = async (id: string) => {
    console.log(id, "id being recieved");
    try {
      let res = await checkFileStatus([id])
      console.log(res.Documents)
      if (!res.Documents.length) return
      res = res.Documents[0]
      console.log(res.status, "files status");
      if (res.status === "SUCCESS") {
        endScanning(res.status)
        // fileUploadTime.current.fileScanning.endTime = Date.now()
        // console.log(new Date(fileUploadTime.current.fileScanning.startTime),"to",  new Date(fileUploadTime.current.fileScanning.endTime), ((fileUploadTime.current.fileUpload.endTime -fileUploadTime.current.fileScanning.startTime) / 1000), "total time");
        // setFileStatus("Passed")
        // setIsFileScanning(false)
      }
      if (res.status === "FAILED") {
        endScanning(res.status)
      }
    } catch (error) {
      console.log(error, "error in fetching status")
    }
  }

  const endScanning = (status:string)=>{
    fileUploadTime.current.fileScanning.endTime = Date.now()
    console.log(fileUploadTime.current);
    console.log("upload time: ", ( fileUploadTime.current.fileUpload.endTime - fileUploadTime.current.fileUpload.startTime)/1000);
    console.log("Scan time: ", (fileUploadTime.current.fileScanning.endTime- fileUploadTime.current.fileScanning.startTime)/1000);
    console.log("Total time: ", (fileUploadTime.current.fileScanning.endTime - fileUploadTime.current.fileUpload.startTime)/1000);
    // new Date(fileUploadTime.current.fileScanning.startTime),"to",  new Date(fileUploadTime.current.fileScanning.endTime), ((fileUploadTime.current.fileScanning.endTime - fileUploadTime.current.fileUpload.startTime) / 1000), "total time"
    setFileStatus(status)
  }

  useInterval(() => {
    fetchFileStatus(fileUplodInfo.current.documentID)
  }, fileStatus==="scanning" ? 2000 : null)

  const getFile = async(fileID:string)=>{
    const documents:any = await getDocuments([fileID])
    const document = documents.documents[0].presignedURL
    console.log(document);
    const testFrame = (window.document.getElementById("testFrame") as any).src = document
  }


  return (
    <>
      <div className="">
        {fileUploadLoader && <DottedLoader></DottedLoader>}
        <Typography variant="callout-02">
          File: {file.fileName}
        </Typography>
        <br />
        <Typography variant="callout-02">
          File status: {fileStatus}
        </Typography>
        <form onSubmit={handleUploadFile} action="">
          <label>Choose a file to upload:</label>
          <input onChange={handleFileChange} type="file"></input>
          <button type="submit">Submit file</button>
        </form>
        <button onClick={()=>getFile(fileUplodInfo.current.documentID)}>Get File</button>
        <iframe id="testFrame" src=""></iframe>
      </div>
    </>
  );
};

FileUploader.propTypes = FileUploaderPropTypes;

export default FileUploader;

import "./ArticleSearchInstruction.scss";
import PropTypes, { InferProps } from "prop-types";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ArticleSearchInstructionPropTypes = {
  icon: PropTypes.string.isRequired,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
}

const ArticleSearchInstruction = (props: InferProps<typeof ArticleSearchInstructionPropTypes>) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <img src={props.icon} alt="" />
      <Typography mt={5} display="block" variant="heading-05" color="text-primary.main">{props.primaryText}</Typography>
      <Typography mt={3} display="block" variant="body-03" color="text-secondary.main">{props.secondaryText}</Typography>
    </Box>
  );
};

ArticleSearchInstruction.propTypes = ArticleSearchInstructionPropTypes;

export default ArticleSearchInstruction;

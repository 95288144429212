import TextField from "../components/TextField/TextField";
import Select from "../components/Select/Select";
import Message from "../components/Message/Message";
import { field, showCondition } from "../types/contactUs"
import InputAdornment from "@mui/material/InputAdornment";
import InfoIcon from "../assets/information-small.svg"
import { attachmentData, contactUsFormData, IssueDetails } from "../services/contactUs";
import DisputeComponent from "./disputeComponent";
import i18n from '../i18n/config';

const getSelectField = (data: field, callback: Function) => {
  if (data.id === 'disputeField') {
    return (
        <div>
        <DisputeComponent data={data} callback={callback}/>
        </div>
    );} else {
    return (
        <Select
            value={data.value || ''}
            onChange={(e: any) => {
              e.target.id = data.id
              callback(e)
            }}
            id={data.id}
            label={data.name}
            options={data.metadata.options}
        />
    );}
}

const readURL = (file: any) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = e => res(e.target!.result);
    reader.onerror = e => rej(e);
    reader.readAsDataURL(file);
  });
};

const getInputField = (data: field, callback: Function) => {
  let endIcon = data.metadata.info && <InputAdornment position="end"><img src={InfoIcon} alt="" /></InputAdornment>

  return (
    <TextField
      error={data.error}
      fullWidth
      helperText={data.helperText}
      multiline={data.metadata?.multiline}
      onChange={callback}
      onBlur={callback}
      label={i18n.t(data.name)}
      id={data.id}
      name={data.name}
      helperType={"standard"}
      required={data.required}
      endIcon={endIcon}
      value={data.value || ''}
      info={data.metadata?.info}
      contentLength={data.value?.trim().length || 0}
      contentCharLimit={data.metadata?.charLimit}
    />
  )
}

const getMessageField = (data: field) => {
  return <Message variant="alert" secondaryMessage={data.metadata.value} sx={{ mt: 0, mb: 5 }} />
}

const getFormComponent = (type: string, data: any, callback: Function) => {
  // console.log("data being recieved in getFormComponent", data);
  if (type === "select") {
    return getSelectField(data, callback)
  } else if (type === "input") {
    return getInputField(data, callback)
  } else if (type === "message") {
    return getMessageField(data)
  }
}

const getFilteredFieldsOnShowConditions = (formFields: field[]): field[] => {
  let filteredFields: field[] = []
  formFields?.forEach((field) => {
    if (evaluateShowConditions(field, filteredFields)) {
      filteredFields.push(field)
    }
  })

  return filteredFields
}

const evaluateShowConditions = (field: field, formFields: Array<field>): boolean => {
  const conditions = field.showConditions
  // console.log(JSON.stringify(field), conditions, "conditions being recieved here");
  if (!conditions.length) return true
  for (let condition of conditions) {
    // console.log(condition.id, formFields.map((field) => field.id),condition.id in formFields.current.map((field) => field.id), "id and map of conditions");
    if (!(formFields.map((formField) => formField.id)).includes(condition.id)) {
      // console.log("returning false here conditionID not found in fields present", field, conditions);
      return false
    }
    // evaluate the show condition to a boolean
    const currentfield = formFields.find((field) => field.id === condition.id)
    for (let conditionValue of condition.values) {
      if (conditionValue === currentfield?.value) {
        //   console.log("returning true here", conditionValue, field, JSON.stringify(field), currentfield.value);
        return true
      }
    }
  }

  // console.log("returning false here, end of loop", field, conditions);
  let fieldIndex: number = formFields.findIndex((formField) => formField.id === field.id && formField.showConditions === field.showConditions)
  const formField = formFields[Number(fieldIndex)]
  if (formField?.value) {
    delete formField.value
  }
  return false
}

const isFieldsIdentical = (oldFields?: field[], newFields?: field[]) => {
  const oldFieldsCommon = oldFields?.map(field => ({
    "id": field.id,
    "name": field.name,
    "type": field.type,
    "required": field.required,
    "canSubmit": field.canSubmit,
    "metadata": field.metadata,
    "showConditions": field.showConditions
  }))

  const newFieldsCommon = newFields?.map(field => ({
    "id": field.id,
    "name": field.name,
    "type": field.type,
    "required": field.required,
    "canSubmit": field.canSubmit,
    "metadata": field.metadata,
    "showConditions": field.showConditions
  }))

  return JSON.stringify(oldFieldsCommon) === JSON.stringify(newFieldsCommon)
}

const evaluateFilesField = (conditions: Array<showCondition>, formFields: Array<field>) => {
  console.log(conditions, formFields, "args for evalfieldsFile");
  if(!formFields || !conditions) {
    console.log("cancelling, as no data")
    return
  }
  for (let condition of conditions) {
    // console.log(condition.id, formFields.map((field) => field.id),condition.id in formFields.current.map((field) => field.id), "id and map of conditions");
    if (!(formFields.map((formField) => formField.id)).includes(condition.id)) {
      console.log("returning false here conditionID for fileupload");
      return false
    }
    // evaluate the show condition to a boolean
    const currentfield = formFields.find((field) => field.id === condition.id)
    for (let conditionValue of condition.values) {
      if (conditionValue === currentfield?.value) {
          console.log("returning true here for file upload");
        return true
      }
    }
  }
  console.log("returning false here for file upload");

  return false
}

const evaluateSubmissionAllowed = (fields: Array<field>) => {
  for (const field of fields) {
    if (!field.required) continue
    if (!field.value) return false
    if(field.id === "disputeField") {
      if (field.value.length < field.metadata.options.length) {
        return false
      }
    }
    if (field.metadata?.charLimit && field.value?.trim().length > field.metadata.charLimit) return false
  }
  return true
}

function buildFormSubmissionData(files: any[], issueType: string, formFieldsToDisplay: field[]): contactUsFormData {
  const modifiedFiles: attachmentData[] = files.map((file) => {
    const fileNameSplit: Array<string> = file.fileName.split(".")
    const lastFileNameSplitIndex: number = Number(fileNameSplit.length) - 1
    const fileExtension = fileNameSplit[Number(lastFileNameSplitIndex)]
    const modifiedFile = {
      fileName: fileNameSplit.slice(0, lastFileNameSplitIndex).join(),
      fileExtension: fileExtension,
      content: file.content.split(',')[1]
    }

    return modifiedFile
  })
  console.log(files, modifiedFiles, "files that are being sent");

  const data: contactUsFormData = {
    issueType: issueType,
    attachments: modifiedFiles,
    details: { ...getDynamicFieldsForFormSubmission(formFieldsToDisplay) }
  }
  console.log("final data :", data)
  return data
}

const getDynamicFieldsForFormSubmission = (formFieldsToDisplay: any): IssueDetails => {
  return formFieldsToDisplay
    .filter((field: field) => field.canSubmit)
    .reduce((obj: any, item: field) => {
      obj[String(item.id)] = item.value
      return obj
    }, {})
}

export {
  getFormComponent,
  getFilteredFieldsOnShowConditions,
  evaluateShowConditions,
  evaluateSubmissionAllowed,
  buildFormSubmissionData,
  evaluateFilesField,
  readURL,
  isFieldsIdentical
}
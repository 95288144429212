import React from 'react';
import { closeWebView } from '../../utils/mobileComms';
import Header from '../Header/Header';
import Cross from '../../assets/cross.svg';
import Hazard from '../../assets/hazard.svg'
import "./ErrorBoundary.scss";
import { Box, Typography } from '@mui/material';
import theme from '../../theme';
import logger from '../../utils/logger';
import i18n from '../../i18n/config';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error(error, errorInfo);
    logger(error.stack || error.message, "error")
  }

  render() {
    if (this.state.hasError) {
      return <Box 
      sx={{
        backgroundColor: theme.palette["bg-primary"].main,
        minHeight: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto 1fr'
      }}
      p={3}
      >
        <Header styles={{ "margin": "0" }}
          handleNavigation={closeWebView}
          icon={Cross} ></Header>
        <div className="center-text-only-screens">
          <img src={Hazard} alt="" />
          {/*this*/}
          <Typography color={theme.palette['text-primary'].main} sx={theme.typography['heading-04']}>{i18n.t("helpcentre_miscellaneous_code_failure_title")}</Typography>
          <Typography color={theme.palette['text-primary'].main} sx={theme.typography['body-02']}>{i18n.t("helpcentre_miscellaneous_code_failure_sub_title")}</Typography>
        </div>
      </Box >
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React from 'react';
import "./DirList.scss";
import ArticleListItem from '../../components/ArticleListItem/ArticleListItem';
import ChevronRight from "../../assets/chevron-right.svg";
import HeadingContainer from '../../components/HeadingContainer/HeadingContainer';
import CurvedListItemContainer from '../../components/CurvedListItemContainer/CurvedListItemContainer';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getSnackbarArgs } from '../../utils/utils';
import { getDirectories } from '../../services/directories';
import { ApiResponse } from '../../types/auth';
import { sendScribeEvent } from '../../utils/scribeHelper';
import Skeleton from '@mui/material/Skeleton';
import NotFound from '../NotFound/NotFound';
import { Box } from '@mui/material';
import useSnackbars from '../../context/Snackbar/Consumer';
import PropTypes, { InferProps } from 'prop-types';

interface ComponentState {
    pageName: string,
    list: Array<any>,
}

const DirListPropTypes = {
    params: PropTypes.any.isRequired,
    openSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    location: PropTypes.any.isRequired
}


class DirList extends React.Component<InferProps<typeof DirListPropTypes>, ComponentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            pageName: '',
            list: [],
        }
    }

    async componentDidMount() {
        const { id } = this.props.params
        const { state } = this.props.location
        // console.log(this.props);
        this.setPageData(id, state)
    }

    async getPageData(id: string, state: any) {
        const getDirectoriesDataResponse = await this.getDirectoriesData(id, state)
        return getDirectoriesDataResponse
    }

    setPageData = async (id: string, state: any) => {
        const historyData = this.getPageDatafromHistory()
        if (historyData.name) {
            this.setStateFromHistory(historyData)
            return
        }
        const getDirectoriesDataResponse = await this.getPageData(id, state)
        if (getDirectoriesDataResponse.error) {
            this.props.openSnackbar(getSnackbarArgs({
                onClose: this.props.closeSnackbar,
                uniqueKey: "directory-list"
            }))
            return
        }

        const list = this.getDirectoriesList(getDirectoriesDataResponse.res)
        const name = this.getDirectoryName(getDirectoriesDataResponse.res)
        this.setState(prevState => {
            return {
                ...prevState,
                pageName: name,
                list: list
            }
        })
        this.setHistoryFormApiData({
            name,
            list
        })

    }

    setHistoryFormApiData(pageData: any) {
        const name = pageData.name
        const list = pageData.list
        window.history.replaceState({
            usr: {
                ...(window.history.state?.usr || {}),
                name,
                list
            },
        }, document.title)
    }

    setStateFromHistory(pageData: any) {
        const name = pageData.name
        const list = pageData.list
        this.setState(prevState => {
            return {
                ...prevState,
                pageName: name,
                list: list
            }
        })
    }

    getPageDatafromHistory = () => {
        return {
            name: window.history.state?.usr?.name,
            list: window.history.state?.usr?.list
        }
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.params.id !== prevProps.params.id) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    pageName: "",
                    list: []
                }
            }
            )
            this.setPageData(this.props.params.id, this.props.location.state)
        }
    }

    getDirectoriesData = async (id: any, state: any) => {
        try {
            const res: any = await getDirectories({ pageId: id, isDirectView: state.isFirstPage })
            return { res } as ApiResponse
        } catch (error) {
            console.error(error)
            return { error } as ApiResponse
        }
    }

    getDirectoriesList = (res: any) => {
        return res["directory"] as Array<string>
    }

    getDirectoryName = (res: any) => {
        return res["pageTitle"] as string
    }

    render() {
        return (
            !(this.state.pageName || this.state.list.length) ?
                (
                    <HeadingContainer text1={this.state.pageName}>
                        <Skeleton className="loader"
                            width={157}
                            height={43}
                            sx={{
                                mb: 4
                            }} />
                        <Skeleton
                            height={212}
                            className="loader"
                            variant="rectangular"
                        />
                    </HeadingContainer>
                ) :
                (<>
                    <HeadingContainer text1={this.state.pageName}>
                        <CurvedListItemContainer>
                            {this.state.list?.length ?
                                (this.state.list?.map((item: any, index: number) => {
                                    return (
                                        <Link to={item.subPages === true ? `/dir-list/${item.id}` : `/article/${item.id}`} 
                                        state={!item.subPages ? {from: "directory"} : {from: "sub-directory"}}
                                        key={'article-list-item-' + index}
                                        className='no-text-decoration' onClick={() => {
                                            sendScribeEvent("help_center_directory_tap", { directory_name: item.title, parent_directory_name: this.state.pageName })
                                        }}>
                                            <ArticleListItem text={item.title} icon={ChevronRight} isCurved={false} />
                                        </Link>
                                    )
                                }))
                                :
                                <Box
                                    sx={
                                        {
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center"
                                        }
                                    }>
                                    <NotFound></NotFound>
                                </Box>
                            }
                        </CurvedListItemContainer>
                    </HeadingContainer>
                </>)
        )
    }
}

// export default withSnackBar(withRouter(DirList));

const MainDirList = (props: any) => {
    const location = useLocation()
    const params = useParams()
    const { openSnackbar, closeSnackbar } = useSnackbars()
    return <DirList location={location} params={params} openSnackBar={openSnackbar} closeSnackBar={closeSnackbar} {...props} />
}

export default MainDirList

import PropTypes, { InferProps } from "prop-types";
import MuiSnackbar, { SnackbarOrigin as MuiSnackbarOrigin } from "@mui/material/Snackbar";
import Message from "../Message/Message";

const SnackbarPropTypes = {
  vertical: PropTypes.oneOf(['top', 'bottom']),
  horizontal: PropTypes.oneOf(['center', 'left', 'right']),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  uniqueKey: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
  variant: PropTypes.string.isRequired,
  primaryIcon: PropTypes.string,
  primaryMessage: PropTypes.string,
  secondaryMessage: PropTypes.string,
  onCloseActionContent: PropTypes.string,
}

const Snackbar = (props: InferProps<typeof SnackbarPropTypes>) => {
  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: props.vertical as MuiSnackbarOrigin["vertical"],
        horizontal: props.horizontal as MuiSnackbarOrigin["horizontal"]
      }}
      open={props.open!}
      onClose={props.onClose}
      key={props.uniqueKey}
      autoHideDuration={props.autoHideDuration}
    >
      <Message 
        onClose={props.onClose}
        variant={props.variant}
        primaryIcon={props.primaryIcon}
        primaryMessage={props.primaryMessage}
        secondaryMessage={props.secondaryMessage}
        onCloseActionContent={props.onCloseActionContent}
        sx={{
          width: "100%",
          m: 4,
        }}
      />
    </MuiSnackbar>
  );
};

Snackbar.propTypes = SnackbarPropTypes;

Snackbar.defaultProps = {
  vertical: 'top',
  horizontal: 'center',
  open: false,
  autoHideDuration: 3000,
}

export default Snackbar;

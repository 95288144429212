import httpClient from './base'; //change this if your axios isnstance is elsewhere

const getDirectories = async (params?:object) => {
    const res = await httpClient.get("/v1/directories", {
        params:params
    })
    return res.data
}

export { getDirectories }

import "./DottedLoader.scss";
import PropTypes, { InferProps } from "prop-types";
import { Box } from "@mui/material";

const DottedLoaderPropTypes = {
  styles: PropTypes.object,
}

const DottedLoader = (props: InferProps<typeof DottedLoaderPropTypes>) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', ...props.styles }}>
      <Box className="dots"></Box>
      <Box className="dots"></Box>
    </Box>
  );
};

DottedLoader.propTypes = DottedLoaderPropTypes;

export default DottedLoader;

const isLoggedIn = () => {
    // Check the access token in session storage to find if user is logged in
    if (sessionStorage.getItem('jwt') !== null)
        return true

    return false
}

const insertJWT = async (token: string) => {
    sessionStorage.setItem("jwt", token)
}

const getJWT = () => {
    return sessionStorage.getItem("jwt")
}

const decodeJWT = (jwt: string) => {
    try {
        let base64Url: string = jwt.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error("error decoding jwt", error);
        return null
    }
}

export { isLoggedIn, insertJWT, getJWT, decodeJWT }

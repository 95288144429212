import { createTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import './index.css';
declare module '@mui/material/styles' {
    interface Theme {
        borderRadius: {
            xsmall: string;
            small: string;
            large: string;
            maximum: string;
            round: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        borderRadius?: {
            xsmall?: string;
            small?: string;
            large?: string;
            maximum?: string;
            round?: string;
        };
    }

    interface Palette {
        'brand-primary': Palette['primary'];
        'brand-secondary': Palette['primary'];
        'brand-tertiary': Palette['primary'];
        'bg-primary': Palette['primary'];
        'bg-secondary': Palette['primary'];
        'bg-tertiary': Palette['primary'];
        'bg-disabled': Palette['primary'];
        'btn-primary': Palette['primary'];
        'btn-secondary': Palette['primary'];
        'text-primary': Palette['primary'];
        'text-secondary': Palette['primary'];
        'text-tertiary': Palette['primary'];
        'text-disabled': Palette['primary'];
        'text-placeholder': Palette['primary'];
        'text-link': Palette['primary'];
        'status-success': Palette['primary'];
        'status-error': Palette['primary'];
        'status-alert': Palette['primary'];
        'status-info': Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        'brand-primary'?: PaletteOptions['primary'];
        'brand-secondary'?: PaletteOptions['primary'];
        'brand-tertiary'?: PaletteOptions['primary'];
        'bg-primary'?: PaletteOptions['primary'];
        'bg-secondary'?: PaletteOptions['primary'];
        'bg-tertiary'?: PaletteOptions['primary'];
        'bg-disabled'?: PaletteOptions['primary'];
        'text-primary'?: PaletteOptions['primary'];
        'text-secondary'?: PaletteOptions['primary'];
        'text-tertiary'?: PaletteOptions['primary'];
        'text-disabled'?: PaletteOptions['primary'];
        'text-placeholder'?: PaletteOptions['primary'];
        'text-link'?: PaletteOptions['primary'];
        'status-success'?: PaletteOptions['primary'];
        'status-error'?: PaletteOptions['primary'];
        'status-alert'?: PaletteOptions['primary'];
        'status-info'?: PaletteOptions['primary'];
    }

    interface TypographyVariants {
        'display-01': React.CSSProperties;
        'display-02': React.CSSProperties;
        'display-03': React.CSSProperties;
        'display-04': React.CSSProperties;
        'display-05': React.CSSProperties;
        'heading-01': React.CSSProperties;
        'heading-02': React.CSSProperties;
        'heading-03': React.CSSProperties;
        'heading-04': React.CSSProperties;
        'heading-05': React.CSSProperties;
        'body-01': React.CSSProperties;
        'body-02': React.CSSProperties;
        'body-03': React.CSSProperties;
        'callout-01': React.CSSProperties;
        'callout-02': React.CSSProperties;
        'callout-03': React.CSSProperties;
        'metadata-01': React.CSSProperties;
        'metadata-01-semibold': React.CSSProperties;
        'metadata-02': React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        'display-01'?: React.CSSProperties;
        'display-02'?: React.CSSProperties;
        'display-03'?: React.CSSProperties;
        'display-04'?: React.CSSProperties;
        'display-05'?: React.CSSProperties;
        'heading-01'?: React.CSSProperties;
        'heading-02'?: React.CSSProperties;
        'heading-03'?: React.CSSProperties;
        'heading-04'?: React.CSSProperties;
        'heading-05'?: React.CSSProperties;
        'body-01'?: React.CSSProperties;
        'body-02'?: React.CSSProperties;
        'body-03'?: React.CSSProperties;
        'callout-01'?: React.CSSProperties;
        'callout-02'?: React.CSSProperties;
        'callout-03'?: React.CSSProperties;
        'metadata-01'?: React.CSSProperties;
        'metadata-01-semibold'?: React.CSSProperties;
        'metadata-02'?: React.CSSProperties;
    }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        'btn-primary': true;
        'btn-secondary': true;
        'brand-primary': true;
        'bg-disabled': true;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        'display-01': true;
        'display-02': true;
        'display-03': true;
        'display-04': true;
        'display-05': true;
        'heading-01': true;
        'heading-02': true;
        'heading-03': true;
        'heading-04': true;
        'heading-05': true;
        'body-01': true;
        'body-02': true;
        'body-03': true;
        'callout-01': true;
        'callout-02': true;
        'callout-03': true;
        'metadata-01': true;
        'metadata-01-semibold': true;
        'metadata-02': true;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        'brand-tertiary': true;
        'status-error': true
    }
}

declare module '@mui/material/TextField' {
    interface TextFieldPropsColorOverrides {
        'bg-tertiary': true;
    }
}

declare module '@mui/material/Alert' {
    interface AlertPropsVariantOverrides {
        'success': true;
        'error': true;
        'alert': true;
        'info': true;
        'primary': true;
    }
}

const textColorTheme = createTheme({
    palette: {
        'text-primary': {
            main: '#FAFAFA',
        },
        'text-secondary': {
            main: '#E9E9E9',
        },
        'text-tertiary': {
            main: '#878787',
        },
        'text-disabled': {
            main: 'rgba(250, 250, 250, 0.2)',
        },
        'text-placeholder': {
            main: '#8173A0',
        },
        'text-link': {
            main: '#AF89F4',
        },
    }
})

const bgColorTheme = createTheme({
    palette: {
        'brand-primary': {
            main: '#771FFF',
        },
        'brand-secondary': {
            main: '#8653E3',
        },
        'brand-tertiary': {
            main: '#896FBD',
            contrastText: textColorTheme.palette['text-primary'].main
        },
        'bg-primary': {
            main: '#0C0120',
        },
        'bg-secondary': {
            main: '#272036',
        },
        'bg-tertiary': {
            main: '#443B59',
        },
        'bg-disabled': {
            main: '#332B44',
        },
        'status-error': {
            main: '#F8316D',
            contrastText: textColorTheme.palette['text-primary'].main
        },
    }
})

const statusColorTheme = createTheme({
    palette: {
        'status-success': {
            main: '#75F9AA'
        },
        'status-error': {
            main: '#F8316D'
        },
        'status-alert': {
            main: '#FFD500'
        },
        'status-info': {
            main: '#4CC9F0'
        },
    }
})

const colorTheme = createTheme(textColorTheme, bgColorTheme, statusColorTheme, {
    palette: {
        'btn-primary': {
            main: bgColorTheme.palette['brand-primary'].main,
            contrastText: textColorTheme.palette['text-primary'].main
        },
        'btn-secondary': {
            main: textColorTheme.palette['text-primary'].main,
            contrastText: textColorTheme.palette['text-primary'].main
        }
    },
    typography: {
        fontFamily: `"Inter", sans-serif`,
        color: textColorTheme.palette['text-primary'].main,
        'display-01': {
            fontWeight: 400,
            fontSize: '80px',
            lineHeight: '88px',
        },
        'display-02': {
            fontWeight: 400,
            fontSize: '72px',
            lineHeight: '80px',
        },
        'display-03': {
            fontWeight: 400,
            fontSize: '64px',
            lineHeight: '72px',
        },
        'display-04': {
            fontWeight: 400,
            fontSize: '56px',
            lineHeight: '64px',
        },
        'display-05': {
            fontWeight: 600,
            fontSize: '48px',
            lineHeight: '56px',
        },
        'heading-01': {
            fontWeight: 400,
            fontSize: '40px',
            lineHeight: '44px',
        },
        'heading-02': {
            fontWeight: 600,
            fontSize: '32px',
            lineHeight: '40px',
        },
        'heading-03': {
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '32px',
        },
        'heading-04': {
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '28px',
        },
        'heading-05': {
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '24px',
        },
        'body-01': {
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '28px',
        },
        'body-02': {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
        },
        'body-03': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
        },
        'callout-01': {
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '24px',
        },
        'callout-02': {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
        },
        'callout-03': {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
        },
        'metadata-01': {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
        },
        'metadata-01-semibold': {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
        },
        'metadata-02': {
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '12px',
        },
    },
    borderRadius: {
        xsmall: '8px',
        small: '12px',
        large: '24px',
        maximum: '100vh',
        round: '50%'
    },
    spacing: [0, 2, 4, 8, 12, 16, 24, 32, 40, 64],
});

const theme = createTheme(colorTheme, {
    components: {
        shadows: ["none"],
        MuiTypography: {
            defaultProps: {
                fontFamily: colorTheme.typography.fontFamily,
                color: textColorTheme.palette['text-primary'].main,
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                style: {
                    borderRadius: colorTheme.borderRadius.maximum,
                    textDecoration: 'unset',
                    textTransform: 'none'
                }
            },
            variants: [
                {
                    props: { variant: 'outlined', color: 'btn-secondary' },
                    style: {
                        background: 'transparent',
                        'border': `1px solid ${colorTheme.palette['text-primary'].main}`,
                        '&:hover': {
                            'background': 'transparent',
                        },
                        // border: `1px solid ${colorTheme.palette['text-primary']}`

                    },
                },
                {
                    props: { variant: 'text' },
                    style: {
                        border: 'none',
                        '&:hover': {
                            'background': 'transparent',
                        },
                    },
                },
            ],
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    '&::before': {
                        borderColor: colorTheme.palette['bg-tertiary'].main,
                        borderWidth: '1px'
                    },
                    '&::after': {
                        borderColor: colorTheme.palette['bg-tertiary'].main,
                        borderWidth: '1px'
                    }
                }
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    whiteSpace: "pre-wrap"
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    textarea: {
                        '&.Mui-disabled': {
                            opacity: "0.35 !important",
                            "-webkit-text-fill-color" : "unset"
                        }
                    }
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    padding: "initial"
                }
            },
            defaultProps: {
                variant: 'error',
            },
            variants: [
                {
                    props: { variant: 'success' },
                    style: {
                        '&.alert-container': { backgroundColor: colorTheme.palette['status-success'].main },
                        '& .alert-text': { color: colorTheme.palette['bg-primary'].main },
                    },
                },
                {
                    props: { variant: 'error' },
                    style: {
                        '&.alert-container': { backgroundColor: colorTheme.palette['status-error'].main },
                        '& .alert-text': { color: colorTheme.palette['text-primary'].main },
                    },
                },
                {
                    props: { variant: 'alert' },
                    style: {
                        '&.alert-container': { backgroundColor: colorTheme.palette['status-alert'].main },
                        '& .alert-text': { color: colorTheme.palette['bg-primary'].main },
                    },
                },
                {
                    props: { variant: 'info' },
                    style: {
                        '&.alert-container': { backgroundColor: colorTheme.palette['status-info'].main },
                        '& .alert-text': { color: colorTheme.palette['bg-primary'].main },
                    },
                },
                {
                    props: { variant: 'primary' },
                    style: {
                        '&.alert-container': { backgroundColor: colorTheme.palette['brand-primary'].main },
                        '& .alert-text': { color: colorTheme.palette['text-primary'].main },
                    },
                },
            ],
        },
    }
});

export default theme;

/**
 * Global classes
 */
const styles = {
    '.border-radius-xsmall': {
        borderRadius: theme.borderRadius.xsmall,
    },
    '.border-radius-small': {
        borderRadius: theme.borderRadius.small,
    },
    '.border-radius-large': {
        borderRadius: theme.borderRadius.large,
    },
    '.border-radius-maximum': {
        borderRadius: theme.borderRadius.maximum,
    },
    '.border-radius-round': {
        borderRadius: theme.borderRadius.round,
    },
    '.curve-endings': {
        // '&:first-of-type': {
        //     borderTopLeftRadius: theme.borderRadius.small,
        //     borderTopRightRadius: theme.borderRadius.small
        // },
        // '&:last-of-type': {
        //     borderBottomLeftRadius: theme.borderRadius.small,
        //     borderBottomRightRadius: theme.borderRadius.small
        // }
        borderRadius: theme.borderRadius.small
    },
    '.line-clamp': {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    '.dots': {
        width: '10.42px',
        height: '10.42px',
        borderRadius: '50%',
        display: 'inline-block',
        margin: '7.16px',
        backgroundColor: theme.palette['text-primary'].main,
        animation: 'dottedLoading 1.5s ease-in-out infinite',
    },
    '.dots:nth-of-type(1)': {
        animationDelay: '0.75s',
    },
    '@keyframes dottedLoading': {
        '0%, 100%': {
            backgroundColor: theme.palette['text-primary'].main,
        },
        '50%': {
            backgroundColor: theme.palette['brand-primary'].main,
        }
    },
    ".loader": {
        backgroundColor: colorTheme.palette["bg-secondary"].main + "!important",
        borderRadius: theme.borderRadius.xsmall,
    }
}

export const inputGlobalStyles = <GlobalStyles styles={styles} />

import "./Message.scss";
import React from 'react';
import PropTypes, { InferProps } from "prop-types";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";

const MessagePropTypes = {
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'error', 'alert', 'info', 'primary']),
  primaryIcon: PropTypes.string,
  primaryMessage: PropTypes.string,
  secondaryMessage: PropTypes.string,
  onCloseActionContent: PropTypes.string,
  sx: PropTypes.object,
}

type MessageVariantType = 'success' | 'error' | 'alert' | 'info' | 'primary'

const Message = React.forwardRef((props: InferProps<typeof MessagePropTypes>, ref: React.ForwardedRef<HTMLDivElement>) => {
  return (
    <Alert
      action={
        props.onCloseActionContent ?
          <Button color="secondary" size="small" onClick={props.onClose!} sx={{ py: 0 }}>
            <Typography variant="callout-03" className="alert-text">{props.onCloseActionContent}</Typography>
          </Button> :
          null
      }
      icon={
        props.primaryIcon ?
          <IconButton><img src={props.primaryIcon} alt="" /></IconButton> :
          false
      }
      variant={props.variant as MessageVariantType}
      className="border-radius-xsmall alert-container"
      ref={ref}
      sx={{
        p: 5, display: 'flex', alignItems: 'center',
        '& .MuiAlert-action': { pt: 0 },
        ...props.sx
      }}
    >
      {
        props.primaryMessage &&
        <Typography display="block" variant="callout-03" className="alert-text">
          {props.primaryMessage}
        </Typography>
      }
      {
        props.secondaryMessage &&
        <Typography display="block" variant="body-03" className="alert-text">
          {props.secondaryMessage}
        </Typography>
      }
    </Alert>
  );
});

Message.propTypes = MessagePropTypes;

Message.defaultProps = {
  onClose: () => { console.error("onClose function not provided for Message component") }
}

export default Message;

import "./Select.scss";
import { IconButton, MenuItem, TextField } from "@mui/material";
import PropTypes, { InferProps } from "prop-types";
import { ComponentPropsWithoutRef, useState } from "react";
import ChevronDown from '../../assets/chevron-down-small.svg';
import ChevronUp from '../../assets/chevron-up-small.svg';
import theme from "../../theme";

const SelectPropTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string
}

const Select = (props: InferProps<typeof SelectPropTypes & ComponentPropsWithoutRef<"select">>) => {
  const [open, setOpen] = useState(false)

  const iconComponentClickHandler = (event: any) => {
    setOpen(true)
  }
  const IconComponent = (props: any) => {
    return (
      <IconButton
        onClick={iconComponentClickHandler}>
        <img src={open ? ChevronUp : ChevronDown} alt="" />
      </IconButton>
    )
  }
  return (
    <TextField
      id={props.id}
      select
      fullWidth
      value={props.value}
      onChange={(e) => {
        props.onChange(e)
      }
      }
      variant="standard"
      label={props.label}
      color="bg-tertiary"
      SelectProps={{
        IconComponent: IconComponent,
        sx: {
          '&::before': { borderBottom: `2px solid ${theme.palette["bg-tertiary"].main}` },
          '&:hover:not(.Mui-disabled)::before': { borderBottom: `2px solid ${theme.palette["bg-tertiary"].main}` },
          whiteSpace: "pre-wrap"
        },
        MenuProps: {
          MenuListProps: { sx: { py: 0 } },
          sx: {
            '.MuiMenu-paper': {
              borderRadius: theme.borderRadius.small,
              mt: 5,
              width: '100%',
            }
          },
          disableAutoFocusItem: true,
        },
        open: open,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false),
      }}
      sx={{
        my: 5,
        '.MuiSelect-select': { color: 'text-primary.main', ...theme.typography["body-02"] },
        label: { color: 'text-placeholder.main', ...theme.typography["body-02"] },
        'label.MuiInputLabel-shrink': { color: 'text-tertiary.main', ...theme.typography["body-02"] },
      }}
    >
      {
        props.options?.map((option, index) => {
          // TODO: create separate component for MenuItem
          return (
            <MenuItem
              value={option}
              key={index}
              id={props.id}
              sx={{
                whiteSpace: "pre-wrap",
                color: 'text-primary.main',
                backgroundColor: 'bg-secondary.main',
                '&.Mui-selected': { backgroundColor: 'bg-tertiary.main' },
                '&.Mui-selected:hover': { backgroundColor: 'bg-tertiary.main' },
                '&.Mui-selected:focus': { backgroundColor: 'bg-tertiary.main' },
                p: 5,
                '&:hover': { backgroundColor: 'bg-tertiary.main' },
                '&:focus': { backgroundColor: 'bg-tertiary.main' }
              }}
            >
              {option}
            </MenuItem>
          )
        })
      }
    </TextField >
  );
};

Select.propTypes = SelectPropTypes;

export default Select;

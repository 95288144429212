const getAllDoctypesToString = (allowedDocTypes: any) => {
    if (!allowedDocTypes || typeof allowedDocTypes !== 'object') {
        return '';
    }
    let allowedDocTypesString = ""
    const order = ['image', 'document']
    for (const key of order) {
        if (Object.prototype.hasOwnProperty.call(allowedDocTypes, key)) {
            const docCategory = allowedDocTypes[key];
            for (const docType of docCategory) {
                let delimeter = ", "
                if (allowedDocTypesString === "") delimeter = ""
                allowedDocTypesString += delimeter + docType
            }
        }
    }
    return allowedDocTypesString
}

const checkDocumentType = (fileName: string, allowedDocTypes: {[x: string]: string[]}) => {
    const splitFileName = fileName.split('.')
    const extensionLocation = splitFileName.length - 1
    const extension = '.' + splitFileName[extensionLocation]
    for (const key in allowedDocTypes) {
        if (Object.prototype.hasOwnProperty.call(allowedDocTypes, key)) {
            const docType = key
            const element: Array<string> = allowedDocTypes[docType];
            if (element.includes(extension.toLocaleLowerCase())) {
                return docType
            }
        }
    }
}

export { getAllDoctypesToString, checkDocumentType }
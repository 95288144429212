import "./CheckboxItem.scss";
import PropTypes, { InferProps } from "prop-types";
import ListItem from "../ListItem/ListItem";
import Checkbox from "@mui/material/Checkbox";
import CheckImg from "../../assets/Checkbox.svg"
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import theme from "../../theme";
import { ComponentPropsWithoutRef } from "react";

const CheckboxItemPropTypes = {
  text: PropTypes.string,
}

const CheckedCustomCheckIcon = () => {
  return (
    <img src={CheckImg} style={{
      "height": "24px",
      "width": "24px",
    }} alt="" />
  )
}

const UncheckedCustomCheckIcon = () => {
  return (
    <Box
      sx={{
        border: "1px solid " + theme.palette["text-primary"].main,
        height: "24px",
        width: "24px",
        borderRadius: "100%",
      }}>
    </Box>
  )
}

const CheckboxItem = (props: InferProps<typeof CheckboxItemPropTypes & ComponentPropsWithoutRef<"input">>) => {
  return (
    <ListItem
      primaryText={
        <Typography variant="body-02" sx={{ color: "text-primary.main" }}>{props.text}</Typography>
      }
      isCurved={false}
      secondaryIcon={<Checkbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
          p: 0
        }}
        onChange={
          props.onChange
      }
        checkedIcon={<CheckedCustomCheckIcon />}
        icon={<UncheckedCustomCheckIcon />}
        {...props}
      />}
    />
  );
};

CheckboxItem.propTypes = CheckboxItemPropTypes;

export default CheckboxItem;

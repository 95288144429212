import "./FileViewer.scss";
import { InferProps } from "prop-types";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import PdfViewer from "../../components/PdfViewer/PdfViewer";

const FileViewerPropTypes = {}

const FileViewer = (props: InferProps<typeof FileViewerPropTypes>) => {
  const { state } = useLocation()
  const { content, type } = state as {
    content: string,
    type: string
  };

  return (
    <Box
      sx={{
        alignSelf: "center",
        height: "100%",
        display: "flex",
        alignItems: "center"
      }}>
      {
        type === 'video' && <video src={content} disablePictureInPicture controls width={"100%"}></video>
      }
      {
        // type === 'document' && <iframe title="previewPDF" src={content} height={"100%"} width={"100%"} frameBorder="0"></iframe>
        type === 'document' && <PdfViewer file={content} />
      }
      {
        type === 'image' && <img alt="previewImage" src={content} width={"100%"}></img>
      }
    </Box>
  );
};

FileViewer.propTypes = FileViewerPropTypes;

export default FileViewer;

import "./CurvedListItemContainer.scss";
import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme } from "@mui/material";

interface CurvedListItemContainerProps {
  children?: ReactNode,
  sx?: SxProps<Theme> | undefined
}

const CurvedListItemContainer = (props: CurvedListItemContainerProps) => {
  return (
    <Box className='curve-endings' sx={{ overflow: 'hidden', ...props.sx }}>
      {props.children}
    </Box>
  );
};

export default CurvedListItemContainer;

import { Box, Button, Typography } from "@mui/material"
import Chat from "../../assets/chat.svg";
import Check from "../../assets/check.svg"
import CurvedListItemContainer from "../../components/CurvedListItemContainer/CurvedListItemContainer";
import ListItem from "../../components/ListItem/ListItem";
import theme from "../../theme";
import { openLink } from "../../utils/mobileComms";
import { useNavigate } from "react-router-dom";
import routes from "../../utils/routes";
import { Trans } from "react-i18next";

const ChatbotStarter = () => {
  const navigate = useNavigate()

  const findIfLinkIsParent = (event: React.MouseEvent<HTMLElement>) => {
    let node = event.target as HTMLElement | null
    const closestLink = node?.closest('A')

    if (closestLink) {
        event.preventDefault()
        if (closestLink.getAttribute('href')) {
            openLink(closestLink.getAttribute('href') as string)
        }
    }

    return null
}

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      gap: 4,
      paddingBottom: "140px"
    }}>
        <img width="140px" src={Chat} alt="" />
        <Typography variant="heading-05" textAlign="center" marginTop={6} marginBottom={4}>Meet AiNi, your GX Bank support chatbot</Typography>
        <CurvedListItemContainer sx={{ width: "100%" }}>
          <ListItem
            primaryText={
              <Typography variant="body-02" sx={{ color: "text-primary.main" }}>
                Powered by AI
              </Typography>
            }
            secondaryText={
              <Typography variant="body-03" sx={{ color: "text-tertiary.main" }}>
                Gives relevant and reliable information
              </Typography>
            }
            primaryIcon={
              <img style ={{ paddingRight: "6px" }} src={Check} alt="" />
            }
            isCurved={false}
            sx={{ mt: 0 }}
          />
          <ListItem
            primaryText={
              <Typography variant="body-02" sx={{ color: "text-primary.main" }}>
                Get instant help, anytime
              </Typography>
            }
            secondaryText={
              <Typography variant="body-03" sx={{ color: "text-tertiary.main" }}>
                Available 24/7 for immediate support
              </Typography>
            }
            primaryIcon={
              <img  style ={{ paddingRight: "6px" }} src={Check} alt="" />
            }
            isCurved={false}
            sx={{ mt: 0 }}
          />
          <ListItem
            primaryText={
              <Typography variant="body-02" sx={{ color: "text-primary.main" }}>
                Not a financial advisor
              </Typography>
            }
            secondaryText={
              <Typography variant="body-03" sx={{ color: "text-tertiary.main" }}>
                AiNi’s responses are for reference only
              </Typography>
            }
            primaryIcon={
              <img  style ={{ paddingRight: "6px" }} src={Check} alt="" />
            }
            isCurved={false}
            sx={{ mt: 0 }}
          />
        </CurvedListItemContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: "column",
            width: "100%",
            backgroundColor: "bg-primary.main",
            gap: 5,
            position: "absolute",
            right: 0,
            left: 0,
            bottom: 0,
        }}>
          <Box
              id='dataPrivacyPolicy'
              component={"section"} 
              sx={{
                color: 'text-primary.main',
                width: "100%",
                fontFamily: theme.typography.fontFamily,
                typography: 'body-03',
                "a": {
                    color: 'text-link.main',
                },
                'h2': {
                    typography: 'callout-01',
                },
                'h3': {
                    typography: 'heading-05',
                },
                'h4': {
                    typography: 'callout-02',
                    color: 'text-tertiary.main',
                },
                'h5': {
                    typography: 'callout-03',
                    color: 'text-tertiary.main',
                },
              }}
              >
              <Trans
                onClick={(event: React.MouseEvent<HTMLElement>) => { findIfLinkIsParent(event)}}
                i18nKey="helpcentre_chatbot_starter_privacy_policy"
                defaults="By proceeding, you agree to all GX <0>Terms & Conditions</0> and <1>Data Privacy Policy</1>"
                components={[
                  <a href="https://gxbank.my/terms-and-conditions" target="_self" rel="external nofollow noopener noreferrer" />,
                  <a href="https://gxbank.my/data-privacy-policy" target="_self" rel="external nofollow noopener noreferrer" />
                ]}
              />
            </Box>
            <Button
              variant="contained"
              onClick={() => { navigate(routes.chat.path) }} 
              color="btn-primary"
              sx={{
                width: '100%',
                flex: 1,
              }}
            >
              <Typography variant="callout-02" p={5}>
                Chat with AiNi
              </Typography>
            </Button>
          </Box>
    </Box>
  )
}

export default ChatbotStarter
import "./NotFound.scss";
import notFound from '../../assets/notFound.svg'
import { InferProps } from "prop-types";
import Typography from "@mui/material/Typography";
import i18n from '../../i18n/config';

const NotFoundPropTypes = {}

const NotFound = (props: InferProps<typeof NotFoundPropTypes>) => {
  return (
    <div className="center-text-only-screens">
       <img src={ notFound } alt="" />
      <Typography component="p" mt={5} variant="heading-05">
          {i18n.t("helpcentre_not_found_page_title")}
      </Typography>
    </div>
  );
};

NotFound.propTypes = NotFoundPropTypes;

export default NotFound;

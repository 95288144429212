import axios from "axios";
import { getJWT, decodeJWT } from "../utils/auth";
import { askForNewToken } from "../utils/mobileComms";
import httpStatus from "../utils/httpStatusCodes";

const httpClient = axios.create({
  baseURL: process.env["REACT_APP_API_URL"] as string,
  headers: {
    Authorization: "Bearer " + (getJWT())
  }
})

// let newTokenRequested: boolean = false

httpClient.interceptors.request.use(async req => {
  try {
    if (getJWT()) {
      const expiringThreshold = 1000 * 60 * 5
      const timeToExpiry = Number(decodeJWT(getJWT() || '').exp) * 1000
      const timeDifference = timeToExpiry - new Date().valueOf()

      if (timeDifference > expiringThreshold) return req
      // if (newTokenRequested) {
      //   console.log("already requested for token", newTokenRequested);
      // }
      console.log("JWT going to expire");
      // newTokenRequested = true
      try {
        askForNewToken()
      } catch (error) {
        console.log(error, "error asking token");
      }
      // newTokenRequested = false
    } else {
      try {
        askForNewToken()
      } catch (error) {
        console.log(error, "error asking token");
      }
    }

  } catch (error) {
    console.log(error, "someError occured");
    // newTokenRequested = false
  }
  finally {
    req.headers["Authorization"] = "Bearer " + getJWT()
    return req
  }
})

httpClient.interceptors.response.use(res => {
  return res
}, error => {
  try {
    console.log(error, "status from response");

    if (error.response.status === httpStatus.UNAUTHORISED) {
      // TODO: move to error page in this scenario
      // const navigate = useNavigate();
      // navigate(routes.error.path)
      // console.log("asking new token from response");
      // askForNewToken()
    }
  } catch (error) {
    console.log(error);
  }

  return Promise.reject(error)
})


export default httpClient

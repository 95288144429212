import "./FileProgress.scss"
import PropsTypes, { InferProps } from "prop-types"
import LinearProgress from "@mui/material/LinearProgress"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { ComponentPropsWithoutRef, useEffect, useState } from "react"

const FileProgressPropTypes = {
  progress: PropsTypes.number,
  text: PropsTypes.string,
  fileStatus: PropsTypes.string,
  messageRef: PropsTypes.any,
}

const FileProgress = (
  props: InferProps<
    typeof FileProgressPropTypes & ComponentPropsWithoutRef<"div">
  >
) => {
  const [progress, setProgress] = useState(10)

  let { fileStatus } = props

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 90
          ? fileStatus === "SUCCESS"
            ? 100
            : 90
          : prevProgress + 10
      )
    }, 800)
    return () => {
      clearInterval(timer)
    }
  }, [fileStatus])

  return (
    <Box>
      <Typography
        variant="body-02"
        sx={{
          color: "text-primary.main",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        Please remain on this screen while your document is being uploaded
      </Typography>
      <LinearProgress
        variant="determinate"
        sx={{
          backgroundColor: "bg-primary.main",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "brand-tertiary.main",
          },
          mt: 3,
          mb: 3,
        }}
        value={progress}
      />
      <Typography variant="body2" color="text-tertiary.main">{`${Math.round(
        progress
      )}%`}</Typography>
    </Box>
  )
}

FileProgress.propTypes = FileProgressPropTypes

export default FileProgress

import React, { useState, useEffect } from 'react';
import Select from "../components/Select/Select";

interface DisputeComponentProps {
    data: any;
    callback: Function;
}

const DisputeComponent: React.FC<DisputeComponentProps> = ({ data, callback }) => {
    const [selectedValues, setSelectedValues] = useState<string[]>(Array(data.metadata.options.length).fill(''));

    useEffect(() => {
        const persistedState = window.history.state?.usr?.[data.id];
        if (persistedState) {
            setSelectedValues(persistedState);
        }
    }, [data.id]);

    const handleSelect = (e: any, index: number, name: string) => {
        const newSelectedValues = [...selectedValues];
        e.target.id = data.id
        e.target.name = name
        newSelectedValues[index] = e.target.value;
        setSelectedValues([...newSelectedValues]);
        callback(e);

        const updatedState = {
            ...window.history.state,
            usr: {
                ...(window.history.state?.usr || {}),
                [data.id]: newSelectedValues
            }
        };
        window.history.replaceState(updatedState, '');
    };

    return (
        <>
            {data.metadata.options.map((option: any, index: any) => {
                return (
                    <Select
                        key={index}
                        value={selectedValues[index] || ''}
                        onChange={(e: any) => {
                            handleSelect(e, index, option.disputeName)
                            }
                        }
                        id={data.id}
                        name={option.disputeName}
                        label={option.disputeName}
                        options={option.disputeOptions}
                    />
                )})}
        </>
    );
};

export default DisputeComponent;
import "./ChatBubbleLoader.scss";

const ChatBubbleLoader = () => {
    return (
        <div className="chat-bubble-loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
    );
} 

export default ChatBubbleLoader
import Typography from "@mui/material/Typography";
import "../GetHelp/GetHelp.scss";
import "./MaintenancePage.scss";
import i18n from "../../i18n/config";
import CurvedListItemContainer from "../../components/CurvedListItemContainer/CurvedListItemContainer";
import {Link} from "react-router-dom";
import routes from "../../utils/routes";
import {sendScribeEvent} from "../../utils/scribeHelper";
import ListItem from "../../components/ListItem/ListItem";
import IconButton from "@mui/material/IconButton";
import Chat from "../../assets/chat.svg";
import GeneralStatusInformation from "../../assets/general-status-infomation.svg";
import Call from "../../assets/call.svg";
import ChevronRight from "../../assets/chevron-right.svg";
import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import theme from "../../theme";
import DOMPurify from "dompurify";
import {openLink} from "../../utils/mobileComms";
import ContactUsFormComponent from "../../components/ContactFormComponent/ContactFormComponent";
import {getUserStatus, UserStatus} from "../../utils/getHelp";
import {getSnackbarArgs} from "../../utils/utils";
import useSnackbars from "../../context/Snackbar/Consumer";
import DottedLoaderBox from "../../components/DottedLoaderBox/DottedLoaderBox";
import { useTranslation } from "react-i18next";
import {TFeatureFlags} from "../../types/featureFlag";
import {getFeatureFlags} from "../../services/featureFlag";

const PHONE_NUMBER = process.env["REACT_APP_PHONE_NUMBER"] as string

const MaintenancePage = () => {
    const transactionIDStateData = window.history.state?.usr?.transactionID
    const [userStatus, setUserStatus] = useState<UserStatus | undefined>();
    const [loading, setLoading] = useState(false);
    const {openSnackbar, closeSnackbar } = useSnackbars()
    const { t } = useTranslation()
    const [featureFlags, setFeatureFlags] = useState<TFeatureFlags>({} as TFeatureFlags);

    const findIfLinkIsParent = (event: React.MouseEvent<HTMLElement>) => {
        let node = event.target as HTMLElement | null
        const closestLink = node?.closest('A')

        if (closestLink) {
            event.preventDefault()
            if (closestLink.getAttribute('href')) {
                openLink(closestLink.getAttribute('href') as string)
            }
        }

        return null
    }

    const createMarkup = (content: string) => {
        return { __html: DOMPurify.sanitize(content) }
    }

    useEffect(() => {//mount
        (async () => {
            setLoading(true)
            const getUserstatusResponse = await getUserStatus()
            if (getUserstatusResponse.error)
                openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "get-user-status-error" }))
            else
                setUserStatus(getUserstatusResponse.res)
            setLoading(false)
        })()
    }, [])

    useEffect(() => {
        (async () => {
            const getFeatureFlagsResponse = await getFeatureFlags()
            if (getFeatureFlagsResponse.error)
                openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "get-form-config" }))
            else {
                setFeatureFlags(getFeatureFlagsResponse.res);
            }
        })()
    }, [])

    return (
        <div className="center-text-only-screens">
            <Box
                sx={{
                    placeItems: "center",
                    m: "auto",
                    mb: 5
                }}>
                <IconButton><img src={GeneralStatusInformation} alt="" /></IconButton>
            </Box>
                <Typography mb={4} component="p" variant="callout-01">
                    {t("helpcentre_maintenance_title")}
                </Typography>
                <Typography component="p"  variant="body-02">
                    {t("helpcentre_maintenance_body_1")}
                </Typography>
            <Typography component="p" mb={7} variant="body-02">
                {t("helpcentre_maintenance_body_2")}
            </Typography>
            <div id="report-fraud" className="list-item">
                <CurvedListItemContainer>
                    { featureFlags?.featureFlags?.chat?.enabled ?
                        <Link
                        to={routes.chat.path}
                        state={{ transactionIDStateData }}
                        className="no-text-decoration"
                        onClick={() => { sendScribeEvent("help_getmorehelp_chat_tap") }}
                        replace>
                    <ListItem
                        primaryText={
                        <Typography variant="body-02" sx={{ color: "text-primary.main" }}>  {t("helpcentre_get_help_page_primary_help_text_1")}</Typography>
                    }
                        primaryIcon={
                        <IconButton><img src={Chat} alt="" /></IconButton>
                    }
                        secondaryIcon={
                        <IconButton><img src={ChevronRight} alt="" /></IconButton>
                    }
                        isCurved={false}
                    />
                    </Link> : ""}
                    { featureFlags?.featureFlags?.call?.enabled ?
                        <a href={`tel:+60${PHONE_NUMBER}`} className="no-text-decoration" onClick={() => { sendScribeEvent("help_getmorehelp_call_tap") }}>
                    <ListItem
                        primaryText={
                        <Typography variant="body-02" sx={{ color: "text-primary.main" }}>{t("helpcentre_maintenance_call_us")}</Typography>
                    }
                        primaryIcon={
                        <IconButton><img src={Call} alt="" /></IconButton>
                    }
                        secondaryIcon={
                        <IconButton><img src={ChevronRight} alt="" /></IconButton>
                    }
                        isCurved={false}
                    />
                    </a> : "" }
                    {featureFlags?.featureFlags?.contactForm?.enabled ?
                        <ContactUsFormComponent status={userStatus}/>
                        : "" }
                </CurvedListItemContainer>
                    </div>
            {
                loading ?
                    <Box
                        sx={{
                            zIndex: "3000",
                        }}
                    >
                        <Box
                            sx={{
                                height: "100vh",
                                width: "100%",
                                position: "fixed",
                                top: "0",
                                left: "0",
                                background: "black"
                            }}>
                        </Box>
                        <DottedLoaderBox
                            sx={{
                                position: "fixed",
                                m: 'auto',
                                top: "50%",
                                left: '50%',
                                transform: "translate(-50%, -50%)"
                            }}
                        />
                    </Box> :
                    <></>
            }
            <div className="fixed-bottom">
                <Box
                    onClick={(event: React.MouseEvent<HTMLElement>) => { findIfLinkIsParent(event) }} id='dataPrivacyPolicy'
                    component={"section"} sx={{
                    color: 'text-primary.main',
                    fontFamily: theme.typography.fontFamily,
                    typography: 'body-03',
                    "a": {
                        color: 'text-link.main',
                    },
                    'h2': {
                        typography: 'callout-01',
                    },
                    'h3': {
                        typography: 'heading-05',
                    },
                    'h4': {
                        typography: 'callout-02',
                        color: 'text-tertiary.main',
                    },
                    'h5': {
                        typography: 'callout-03',
                        color: 'text-tertiary.main',
                    },
                }}
                    dangerouslySetInnerHTML={createMarkup(`\n\n<div>${i18n.t("helpcentre_data_privacy_policy_body_1")} <a href="https://gxbank.my/data-privacy-policy" target="_self" rel="external nofollow noopener noreferrer">${i18n.t("generic_data_privacy_policy")}</a>${i18n.t("helpcentre_data_privacy_policy_body_2")}</div>\n` || '')}>
                </Box>
            </div>
        </div>
    );
};

export default MaintenancePage;
export enum MessageType {
    USER = "USER",
    AGENT = "AGENT",
    CLOSED_CONNECTION = "CLOSED_CONNECTION",
    WELCOME = "WELCOME",
    UNREAD_MESSAGE_DIVIDER = "UNREAD_MESSAGE_DIVIDER",
    SELECT_TO_START_CHAT = "SELECT_TO_START_CHAT"
}
  
export const dividerMessageTypes: string[] = [MessageType.CLOSED_CONNECTION, MessageType.UNREAD_MESSAGE_DIVIDER, MessageType.SELECT_TO_START_CHAT]
  
export const chatPageSize = 10
  
export const POLLING_INTERVAL = 3000;

export enum ChatbotActionKind {
    SET_IS_CHATBOT_RESPONSE_LOADING = 'SET_IS_CHATBOT_RESPONSE_LOADING',
    SET_IS_CHATBOT_CHAT = 'SET_IS_CHATBOT_CHAT',
    SET_SHOULD_HANDOVER_TO_AGENT = 'SET_SHOULD_HANDOVER_TO_AGENT',
    SET_IS_AGENT_HANDOVER_LOADING = 'SET_IS_AGENT_HANDOVER_LOADING',
    ADD_SUBMITTED_FEEDBACK_MESSAGES = 'ADD_SUBMITTED_FEEDBACK_MESSAGES',
}

export enum FeedbackState {
    ENABLED = "ENABLED",
    DISABLED = "DISABLED",
    SUBMITTED = "SUBMITTED"
}

export const chatIssueTypesWithChatbot = {
    "en": [
        "Report a fraud",
        "What is pre-authorisation?",
        "What should I do if my recipient did not receive my fund transfer?",
        "How is daily interest calculated?",
        "How do I apply for FlexiCredit?"
    ],
    "ms": [
        "Melaporkan penipuan",
        "Akaun simpanan GX",
        "Kad GX",
        "Pembayaran & Pemindahan",
        "Lain lain"
    ]
}

export const chatIssueTypesWithoutChatbot = {
    "en": [
        "Report a fraud",
        "Payments & transfers",
        "GX Card",
        "GX FlexiCredit",
        "All other matters"
    ],
    "ms": [
        "Melaporkan penipuan",
        "Akaun simpanan GX",
        "Kad GX",
        "Pembayaran & Pemindahan",
        "Lain lain"
    ]
}


import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

type ListItemPropTypes = {
  primaryText?: ReactNode,
  secondaryText?: ReactNode,
  tertiaryText?: ReactNode,
  primaryIcon?: ReactNode,
  secondaryIcon?: ReactNode,
  isCurved?: boolean,
  sx?: object,
}

const ListItem = (props: ListItemPropTypes & ComponentPropsWithoutRef<"div">) => {
  function classes() {
    return `${props.isCurved ? 'curve-endings' : ''}`;
  }

  return (
    <Stack onClick={props.onClick} direction="row" className={classes()} sx={{ p: 5, mt: '1px', backgroundColor: "bg-secondary.main", ...props.sx }}>
      <Stack direction="row" width="100%" spacing={3}>
        {
          props.primaryIcon &&
          <Box sx={{ mr: 3, alignSelf: 'center' }}>
            {props.primaryIcon}
          </Box>
        }
        <Box width="100%">
          <Stack>
            {props.primaryText}
            {props.secondaryText}
            {props.tertiaryText}
          </Stack>
        </Box>
        {props.secondaryIcon}
      </Stack>
    </Stack>
  );
}

ListItem.defaultProps = {
  isCurved: true,
  sx: {},
}

export default ListItem;
import "./ContactFormFailed.scss";
import { Box, Button, Typography } from "@mui/material";
import theme from "../../theme";
import Hazard from '../../assets/hazard.svg'
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../utils/routes";
import { PersistedData } from "../../types/contactUs";
import i18n from '../../i18n/config';

const ContactFormFailed = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { persistedData } = (state || {}) as {
    persistedData: PersistedData,
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette["bg-primary"].main,
        minHeight: 'calc(100vh - 48px)',
        display: 'grid',
        gridTemplateRows: '1fr auto',
        padding: 0
      }}
      p={3}
    >
      <Box className="center-text-only-screens" display="grid" gap={4} sx={{
        placeItems: "center"
      }}>
        <img src={Hazard} alt="" />
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography color={theme.palette['text-primary'].main} sx={theme.typography['heading-04']}>{i18n.t("helpcentre_contact_us_error_header")}</Typography>
          <Typography color={theme.palette['text-primary'].main} sx={theme.typography['body-02']}>{i18n.t("helpcentre_contact_us_error_body")}</Typography>
        </Box>
      </Box>
      <Box display='flex' flexDirection='column'>
        <Button
          variant="contained"
          type="submit"
          onClick={() => navigate(routes.contactUs.path, {
            state: {
              persistedData
            }
          })}
          sx={{
            backgroundColor: "brand-primary.main",
            '&:hover': {
              backgroundColor: 'brand-primary.main'
            },
            '&.Mui-disabled': {
              backgroundColor: 'bg-disabled.main',
            },
          }}
        >
          <Typography variant="callout-02" p={5} sx={{ color: 'text-primary.main' }}>
            {i18n.t("generic_try_again")}
          </Typography>
        </Button>
        <Button sx={{ cursor: 'default' }}>
          <Typography onClick={() => navigate(routes.home.path)} variant="callout-02" p={5} sx={{ color: 'text-primary.main', cursor: 'pointer' }}>
            {i18n.t("generic_close")}
          </Typography>
        </Button>
      </Box>
    </Box >
  );
};

export default ContactFormFailed;


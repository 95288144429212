import { Box, Typography } from "@mui/material";
import "./FileUploader2.scss";
import PropTypes, { InferProps } from "prop-types";
import { FormEvent, useEffect, useRef, useState } from "react";
import { readURL } from "../../utils/dynamicForm";
import { fileData } from "../../types/fileUpload";
import { getDocuments } from "../../services/documents";
import DottedLoader from "../DottedLoader/DottedLoader";
import { useDocUpload } from "../../hooks/useDocUpload";

const FileUploader2PropTypes = {}

const FileUploader2 = (props: InferProps<typeof FileUploader2PropTypes>) => {
  const [file, setFile] = useState({} as fileData)
  const [fileUploadLoader, setFileUploadLoader] = useState(false)
  const fileUplodInfo = useRef({} as any)
  const fileUploadTime = useRef({
    fileUpload: {
      startTime: 0,
      endTime: 0
    },
    fileScanning: {
      startTime: 0,
      endTime: 0
    }
  })

  const onChange = (e: any) => {
    console.log(e, "event from hook onchange")
  }

  const [fileStatus, setUploadFile] = useDocUpload()

  useEffect(()=>{
    console.log("file status changed, main page", fileStatus);
  },[fileStatus])

  const handleFileChange = async (ev: any) => {
    if (!ev.target.files) return
    console.log(ev.target.files);
    const file = ev.target.files[0]
    const splitFileName = file.name.split('.')
    const extensionLocation = splitFileName.length - 1
    const fileExtension = splitFileName[extensionLocation]
    const fileObj: fileData = {
      fileName: file.name,
      fileContent: (await readURL(file) as string).split(',')[1],
      fileExtension,
      fileType: "contactUsForm",
    }
    if (typeof setUploadFile === "function") {
      // console.log(setUploadFile, "calling setUploadFile");
      setUploadFile(fileObj)
    }
    setFile(fileObj)
    ev.target.value = ""
  }

  const getFile = async (fileID: string) => {
    const documents: any = await getDocuments([fileID])
    const document = documents.documents[0].presignedURL
    console.log(document);
    (window.document.getElementById("testFrame") as any).src = document
  }


  return (
    <>
      {fileUploadLoader && <DottedLoader></DottedLoader>}
      <Typography variant="callout-02">
        <>
        File: {file.fileName}
        <br />
        File status: {fileStatus}
        </>
      </Typography>
      <label>Choose a file to upload:</label>
      <input onChange={handleFileChange} type="file"></input>
      <button onClick={() => getFile(fileUplodInfo.current.documentID)}>Get File</button>
      <iframe id="testFrame" src=""></iframe>
    </>
  );
};

FileUploader2.propTypes = FileUploader2PropTypes;

export default FileUploader2;

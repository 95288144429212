import "./ChatInput.scss";
import PropsTypes, { InferProps } from "prop-types";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import theme from "../../theme";

const ChatInputPropTypes = {
  placeholder: PropsTypes.string,
  value: PropsTypes.string.isRequired,
  updateInputText: PropsTypes.func.isRequired,
  sx: PropsTypes.object,
  disabled: PropsTypes.bool
}

const ChatInput = (props: InferProps<typeof ChatInputPropTypes>) => {
  return (
    <Paper
      component="form"
      classes={{ root: 'border-radius-large' }}
      sx={{
        px: 5,
        py: 3,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'bg-secondary.main',
        boxShadow: 'none',
        '&:Mui-disabled': {
          opacity: 0.35
        },
        ...props.sx
      }}
    >
      <Typography component="div" variant="body-02" width='100%'>
        <InputBase
          multiline
          fullWidth
          disabled = {props.disabled ? props.disabled : false}
          sx={{
            flex: 1,
            color: 'text-primary.main',
            'textarea::placeholder': { color: 'text-secondary.main' },
            caretColor: theme.palette["text-primary"].main,
          }}
          placeholder={props.placeholder || ""}
          value={props.value}
          maxRows="4"
          onChange={props.updateInputText}
        />
      </Typography>
    </Paper>
  );
};

ChatInput.propTypes = ChatInputPropTypes;

export default ChatInput;

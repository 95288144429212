import httpClient from './base'; //change this if your axios instance is elsewhere

type Params = {
    cache?: boolean
}

export type UserDetails = {
    userName?: string,
    userEmail?: string,
    activeProfileDomain?: string,
    locale?: string,
    isEmailVerified: boolean,
    isOnboarded: boolean,
    isFirstFundDone: boolean,
    chatbotEnabled: boolean,
    isChatbotAgreementMissing: boolean
}

const getUserDetails = async (params?: Params): Promise<UserDetails> => {
    // TODO: Set headers in interceptors instead
    const res = await httpClient.get("/v1/get-user-details", {
        params: params ?? { cache: true }
    })
    return res.data
}

export { getUserDetails }

import { SnackBarMessage } from "../types/contactUs"

const cardDisputePostSubmissionBottomSheet = {
    image: "https://assets.sgbank.dev/dev/helpCentre/images/illustration-general-lock.svg",
    heading: "Your transaction dispute has been submitted. Do you want to freeze your card? ",
    subHeading: "This prevents any further transactions being made on your card. Only you can unfreeze your card.",
    actions: [
        {
            type: "button",
            variant: "outlined",
            color: "btn-secondary",
            text: "No",
            action: null
        },
        {
            type: "button",
            variant: "contained",
            color: "btn-primary",
            text: "Yes",
            action: "freezeCard"
        }
    ]
}

const snackBarMessages = new Map<string, SnackBarMessage>([
    ["cardStatusFetchFail", {
        message: "Dispute submitted. Consider freezing your card via Card Settings to keep yourself safe.",
        variant: "primary",
        key: "card-status-retrieval-failed"
    }],
    ["cardFreezeFail", {
        message: "Dispute submitted. We could not freeze your card. Please try again via Card Settings.",
        variant: "error",
        key: "card-status-retrieval-failed"
    }],
    ["cardFreezeSuccess", {
        message: "Dispute submitted. Your card has been frozen. Hang tight while we investigate.",
        variant: "primary",
        key: "card-freeze-success"
    }],
    ["cardDisputeSubmitSuccess", {
        message: "Dispute submitted. Hang tight, we will drop you an email soon!",
        variant: "primary",
        key: "card-dispute-submit-success"
    }],
])

export { cardDisputePostSubmissionBottomSheet, snackBarMessages }
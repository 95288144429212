import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DottedLoaderBox from "../../components/DottedLoaderBox/DottedLoaderBox";
import { insertJWT } from "../../utils/auth";
import { validateJWT } from "../../services/auth";
import { notifyPageLoad } from "../../utils/mobileComms";
import routes from "../../utils/routes";
import { commsObj } from "../../types/auth"
import { useTranslation } from "react-i18next"
import { getLocale } from "../../utils/utils";


const RedirectPage = (props: any) => {
    const navigate = useNavigate();
    const [showMoreInfo, setShowMoreInfo] = useState(false)
    const { i18n, t } = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(getLocale())
        const onPageLoad = () => {
            notifyPageLoad()
        };

        setTimeout(() => {
            setShowMoreInfo(true)
        }, 2000);

        // Check if the page has already loaded
        if (document.readyState === "complete") {
            onPageLoad();
        } else {
            window.addEventListener("load", onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener("load", onPageLoad);
        }
    }, [i18n]);

    const redirect = (route = routes.home.path, data?: object) => {
        console.log(route, "route bring recieved");
        navigate(route, {
            state: {
                isFirstPage: true,
                ...data,
            },
            replace: true
        })
    }

    const loginUser = async (commsObj: commsObj) => {
        console.log(commsObj, "commsObj Being recieved");

        if (!await validateJWT(commsObj.user.access_token)) {
            setShowMoreInfo(true)
            return
        }

        insertJWT(commsObj.user.access_token)
        var locale = getLocale()
        i18n.changeLanguage(locale)

        if (commsObj.payload.url.destination) {
            redirect(commsObj.payload.url.destination, commsObj.payload.data)
        } else {
            redirect(routes.home.path, commsObj.payload.data)
        }
    }

    (window as any)["loginUser"] = loginUser

    return (
        <div className="center-text-only-screens">
            <DottedLoaderBox sx={{
                m: "auto",
                mb: 8
            }} />
            {showMoreInfo && <>
                <Typography mb={4} component="p" variant="callout-01">
                    {t("helpcentre_redirect_page_title")}
                </Typography>
                <Typography component="p" mb={7} variant="body-02">
                    {t("helpcentre_redirect_page_body_1")}
                </Typography>
                
                <Typography component="p" variant="body-02">
                {t("helpcentre_redirect_page_body_2")}
                </Typography>
            </>}
        </div>
    )
}

export default RedirectPage


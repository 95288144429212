import ActionSheet from "../ActionSheet/ActionSheet"
import Checkmark from '../../assets/checkmark.svg';
import { isClicked } from "../../utils/contactForm";
import { useNavigate } from "react-router-dom";
import routes from "../../utils/routes";
import { Button, Typography } from "@mui/material";
import i18n from '../../i18n/config';

type SubmissionSuccessBottomSheetProps = {
    openBottomSheet: boolean
    setOpenBottomSheet: (value: boolean) => void
    userEmail: string
}

const submissionSuccessBottomSheetButton = 'submission-success-bottom-sheet-button'

const SubmissionSuccessBottomSheet = (props: SubmissionSuccessBottomSheetProps) => { 
    const navigate = useNavigate()

    return (
    <ActionSheet
        icon={<img src={Checkmark} alt="" />}
        open={props.openBottomSheet}
        onClose={(e, reason) => {
        if (reason != 'backdropClick' && isClicked(submissionSuccessBottomSheetButton, e.target)) {
            props.setOpenBottomSheet(false)
            navigate(routes.home.path)
        }
        }}
        title={i18n.t("helpcentre_contact_us_enquiry_submit_success_header")}
        description={`${i18n.t("helpcentre_contact_us_enquiry_submit_success_body_1")}${props.userEmail}${i18n.t("helpcentre_contact_us_enquiry_submit_success_body_2")}`}
        primaryButton={<Button
        id={submissionSuccessBottomSheetButton}
        variant="contained"
        onClick={() => {
            props.setOpenBottomSheet(false)
            navigate(routes.home.path)
        }}
        sx={{
            backgroundColor: "brand-primary.main",
            '&:hover': {
            backgroundColor: 'brand-primary.main'
            },
            flex: 1
        }}
        >
        <Typography variant="callout-02" p={5} sx={{ color: 'text-primary.main' }}>
            {i18n.t("generic_ok")}
        </Typography>
    </Button>}
  />)
}

export default SubmissionSuccessBottomSheet 
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpApi from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

const ENV = process.env["REACT_APP_DATADOG_ENV"] as string

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
            crossDomain: true,
            withCredentials: false,
            customHeaders: {
                'Authorization': 'Bearer b6b26440905a7d7c57acaa75efcd9800a7592a5e06e00cc08e30eb148ac03919'
            }
        },
        // lng: "en",
        fallbackLng: "en",
        debug: ENV === "dev" || ENV === "stg" ? true : false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
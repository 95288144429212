import axios from 'axios';
import { setDomain, setLocale } from '../utils/utils';
// import httpClient from './base'; //change this if your axios isnstance is elsewhere

const validateJWT = async (access_token: string) => {
    // if(sessionStorage.getItem("token")) return true
    try {
        const baseURL = process.env["REACT_APP_API_URL"] as string
        console.log("access token must be: ", access_token);
        // httpClient.defaults.headers.common = {
        //     "Authorization": "Bearer " + access_token
        // }
        let res = await axios.get(baseURL + "/v1/get-user-details", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })

        console.log(res);
        if (res) {
            console.log("valid JWT");
            setLocale(res.data.locale)
            setDomain(res.data.activeProfileDomain)
        }
        return true
    } catch (error) {
        console.log("invalid JWT", error);
        return false
    }
}

export { validateJWT }
import httpClient from './base';
import {ApiResponse} from "../types/auth";

const getFeatureFlagsData = async () => {
    const res = await httpClient.get("/v1/feature-flags")
    return res.data
}

const getFeatureFlags = async () => {
    try {
        const res = await getFeatureFlagsData()
        return { res: res } as ApiResponse
    } catch (error) {
        console.error(error)
        return { res: "", error } as ApiResponse
    }
}

export { getFeatureFlagsData, getFeatureFlags }

import { datadogRum } from "@datadog/browser-rum";

const ENV = process.env["REACT_APP_DATADOG_ENV"] as string
const clientToken = process.env["REACT_APP_DATADOG_CLIENT_TOKEN"] as string
const applicationId = process.env["REACT_APP_DATADOG_APPLICATION_ID"] as string

const initDatadog = () => {
    datadogRum.init({
        applicationId: applicationId,
        clientToken: clientToken,
        site: 'datadoghq.com',
        service:'dbmy-help-center',
        env:ENV,
        sessionSampleRate:100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel:'mask',
        enableExperimentalFeatures: ['clickmap']
    });

    datadogRum.startSessionReplayRecording();
}

export default initDatadog
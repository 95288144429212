import './HeadingContainer.scss'
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';

interface props {
    text1?: string,
    text2?: string,
    text3?: string,
    text4?: string,
    children?: React.ReactNode;
}


const HeadingContainer = (props: props) => {
    return (
        <Box mb={6} className="heading-container">
            {props.text1 &&
                <Typography mb={5} display="block" variant="heading-04" color="text-secondary.main">{props.text1}</Typography>
            }
            {props.text2 &&
                <Typography mb={3} display="block" variant="body-03" color="text-secondary.main">{props.text2}</Typography>
            }
            {props.text3 &&
                <Typography mb={3} display="block" variant="metadata-01-semibold" color="text-tertiary.main">{props.text3}</Typography>
            }
            {props.text4 &&
                <Typography mb={3} display="block" variant="callout-01" color="text-primary.main">{props.text4}</Typography>
            }
            {props.children}
        </Box>
    )
}

export default HeadingContainer
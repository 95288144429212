import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../../utils/auth';

const ProtectedRoute = ({ redirectPath = '/redirect', children }: any) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate(redirectPath);
        }
    }, [])

    if (isLoggedIn())
        return children;
};

export default ProtectedRoute;
const chatDocumentUploadConfig = {
    "maxFileSizeInMB": 7,
    "totalFileSizeInMB": 7,
    "maxDocuments": 5,
    "requiredConditions": [
        {
            "id": "reasonForDispute",
            "values": [
                "I've only made one transaction. The above transaction(s) is/are duplicate(s).",
                "I was charged more than initially quoted.",
                "I did not agree to the currency of the transaction.",
                "I did not agree to the exchange rate of the transaction."
            ]
        }
    ],
    "allowedDocTypes": {
        "document": [
            ".pdf"
        ],
        "image": [
            ".jpg",
            ".jpeg",
            ".png"
        ]
    }
}

export { chatDocumentUploadConfig }
import { fileData } from '../types/fileUpload';
import httpClient from './base'; //change this if your axios isnstance is elsewhere

const uploadDocument = async (fileData: fileData) => {
    const res = await httpClient.post("/v1/upload-document", fileData)
    return res.data
}

const checkFileStatus = async (fileID: Array<string>) => {
    const res = await httpClient.post("/v1/get-document-status", {
        "documentID": fileID
    })
    return res.data
}

const getDocuments = async (fileID: Array<string>) => {
    const res = await httpClient.post("/v1/get-documents", {
        "documentID": fileID
    })
    return res.data
}

export { uploadDocument, checkFileStatus, getDocuments }
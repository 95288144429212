import { useState, useRef, useEffect } from "react"
import { useInterval } from "../utils/utils"
import { checkFileStatus, uploadDocument } from "../services/documents"

export const useDocUpload = () => {
    // 1. Recieve a file
    // 2. Start upload and return the state variable which let's us know when the state of the file has changed
    // 3. On finishing/failing, trigger an event(using callback function)
    // 4. Error scenarios(Another file requested when one is underway)
    const [fileStatus, setFileStatus] = useState("" as uploadState)
    const [file, setFile] = useState({} as any)
    const fileUplodInfo = useRef({} as any)

    type uploadState = "NOTSTARTED" | "UPLOADING" | "UPLOADED" | "SCANNING" | "FAILED" | "SUCCESS"

    const allowedStatesToStart = ["", "SUCCESS", "FAILED", "FAILED"]

    const setUploadFile: Function = (file: any) => {
        if (!file.fileName) return
        console.log("setUploadFile called", file, allowedStatesToStart.includes(fileStatus) && file.fileName)
        if (!(allowedStatesToStart.includes(fileStatus) && file.fileName)) throw new Error("A file is currently being processed")
        console.log("settign new file now, all passed");
        setFile(file)
        handleUploadFile(file)
    }
    useEffect(() => {
        console.log("file changed", file)
        // if (!(allowedStatesToStart.includes(fileStatus) && file.fileName)) return
        // handleUploadFile(file)
    }, [file])

    const handleUploadFile = async (file: any) => {
        console.log("UPLOADING");
        setFileStatus("UPLOADING")
        try {
            const res = await uploadDocument(file)
            if (res) {
                setFileStatus("UPLOADED")
                fileUplodInfo.current = {
                    name: file.fileName,
                    id: res.documentID
                }
                console.log(fileUplodInfo, "id being set");
            }
            fetchFileStatus(res.id)
            setFileStatus("SCANNING")
        } catch (error) {
            setFileStatus("FAILED")
        }
        // setFileUploadLoader(false)
        //if success start pinging the check endpoint until you get a valid response or time out 
    }

    const fetchFileStatus = async (id: string) => {
        console.log(id, "id being recieved");
        try {
            let res = await checkFileStatus([id])
            console.log(res.Documents)
            if (!res.Documents.length) return
            res = res.Documents[0]
            console.log(res.status, "files status");
            if (res.status === "SUCCESS") {
                setFileStatus(res.status)
            }
            if (res.status === "FAILED") {
                setFileStatus(res.status)
            }
        } catch (error) {
            console.log(error, "error in fetching status")
        }
    }

    useInterval(() => {
        fetchFileStatus(fileUplodInfo.current.id)
    }, fileStatus === "SCANNING" ? 2000 : null)

    return [fileStatus, setUploadFile, fileUplodInfo.current]
}
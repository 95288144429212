// import { commsObj } from "./types"
import { matchPath } from "react-router-dom";
import { insertJWT } from "../utils/auth";
import { validateJWT } from "../services/auth";
import routes from "./routes";
import { goBackHelper } from "./utils";

const androidBridge = (window as any).JSBridge
const iosBridge = (window as any).webkit?.messageHandlers
const closeWebView = () => {
    console.log("closing webview...");
    try {
        androidBridge.goExit()

    } catch (error) {

    }
    try {
        iosBridge.goExit.postMessage("")
    } catch (error) {

    }
}
const askForNewToken = () => {
    console.log("asked for a new token");
    try {
        androidBridge.getAccessToken()
    } catch (error) {

    }

    try {
        iosBridge.getAccessToken.postMessage("")
    } catch (error) {

    }
}

const openLink = (link: string) => {
    console.log("opening link", link);
    try {
        androidBridge.openUrl(link)
    } catch (error) {

    }
    try {
        iosBridge.openUrl.postMessage(link)
    } catch (error) {

    }
}

const insertNewToken = async (token: string) => {
    if (!await validateJWT(token)) return "error"
    insertJWT(token)
    return "success"
}

const notifyPageLoad = () => {
    console.log("Notifying page load");
    try {
        androidBridge.pageLoaded()
    } catch (error) {

    }

    try {
        iosBridge.pageLoaded.postMessage("")
    } catch (error) {

    }
}

const checkPermissions = () => {
    console.log("checking permission...");
    try {
        androidBridge.checkPermissions()
    } catch (error) {

    }
    try {
        iosBridge.checkPermissions.postMessage("")
    } catch (error) {

    }
}

const goBack = (navigator:Function) => {
    const currRoute = Object.keys(routes).find((key: string) => {
        const route = key
        return matchPath({ path: routes[route].path }, window.location.pathname)
    })
    if (!currRoute) {
        window.history.back()
        return
    }

    const headerProps = routes[currRoute].header?.props

    goBackHelper(headerProps, navigator)
}

const webNavigate = (navigator: Function) => {
    return (route: string) => {
        navigator(route)
    }
}

export { closeWebView, askForNewToken, openLink, insertNewToken, notifyPageLoad, checkPermissions, goBack, webNavigate }

import "./BottomSheet.scss";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import theme from "../../theme";
import { ReactNode } from "react";

type BottomSheetPropTypes = {
  children: ReactNode,
  open: boolean,
  onClose: (v?: any) => void,
  sx?: object
}

const BottomSheet = (props: BottomSheetPropTypes) => {
  return (
    <Drawer
      PaperProps={{
        square: false,
        sx: {
          borderTopLeftRadius: theme.borderRadius.large,
          borderTopRightRadius: theme.borderRadius.large,
          borderBottomLeftRadius: "0 !important",
          borderBottomRightRadius: "0 !important",
          background: "transparent"
        }
      }}
      anchor="bottom"
      open={props.open}
      onClose={props.onClose}
    >
      <Box
        sx={{
          width: 'auto',
        }}
        role="presentation"
        onClick={props.onClose}
        onKeyDown={props.onClose}
      >
        {props.children}
      </Box>
    </Drawer >
  );
};

export default BottomSheet;

import "./TextField.scss";
import PropTypes, { InferProps } from "prop-types";
import theme from "../../theme";
import { Box, Button, TextField as MuiTextField, Typography } from "@mui/material";
import { ComponentPropsWithoutRef, useState } from "react";
import BottomSheet from "../BottomSheet/BottomSheet";
import i18n from '../../i18n/config';

const TextFieldPropTypes = {
  contentLength: PropTypes.number,
  contentCharLimit: PropTypes.number,
  endIcon: PropTypes.element,
  sx: PropTypes.object,
  info: PropTypes.shape({
    heading:PropTypes.string,
    content:PropTypes.string
  }),
  helperType: PropTypes.oneOf(["counter", "standard"])
}

const TextField = (props: InferProps<typeof TextFieldPropTypes & ComponentPropsWithoutRef<typeof MuiTextField>>) => {
  const [openBottomSheet, setOpenBottomSheet] = useState(false)

  return (
    <>
    <MuiTextField
      id={props.id || ""}
      error={props.error}
      fullWidth={props.fullWidth || false}
      multiline={props.multiline || false}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      variant="standard"
      label={props.label}
      color="bg-tertiary"
      helperText={
        <Box display="flex" justifyContent={props.helperText ? "space-between" : "end"}>
          {props.helperText && <Typography sx={{
              textAlign: 'left',
              color: props.error ?  'status-error.main' : 'text-placeholder.main'
            }}
            variant="metadata-01"
          >
            {props.helperText}
          </Typography>}
          <Typography sx={{
              textAlign: 'right',
              color: (props.contentLength?.toString() && props.contentCharLimit) && (props.contentLength <= props.contentCharLimit) ? 'text-placeholder.main' : 'status-error.main'
            }}
            variant="metadata-01"
          >
            {(props.contentLength?.toString() && props.contentCharLimit) ? `${props.contentLength}/${props.contentCharLimit} ${i18n.t("generic_characters")}` : ''}
          </Typography>
        </Box>
      }
      InputProps={{
        sx: {
          caretColor: theme.palette["text-primary"].main,
          '&::before': { borderBottom: `2px solid ${theme.palette["bg-tertiary"].main}` },
          '&:hover:not(.Mui-disabled)::before': { borderBottom: `2px solid ${theme.palette["bg-tertiary"].main}` },
        },
        endAdornment: (
          <Box
            onClick={() => {
              setOpenBottomSheet(true)
              console.log("End icon was clicked");
            }}>
            {props.endIcon}
          </Box>
        )
      }}
      InputLabelProps={{
        error: false
      }}
      sx={{
        my: 5,
        '.MuiInput-input': { color: 'text-primary.main', ...theme.typography["body-02"] },
        label: { color: 'text-placeholder.main', ...theme.typography["body-02"] },
        'label.MuiInputLabel-shrink': { color: 'text-tertiary.main', ...theme.typography["body-02"] },
        ...props.sx
      }}
    />
    {props.info && 
    <BottomSheet open={openBottomSheet} onClose={()=>setOpenBottomSheet(false)}>
      <Box p={6} sx={{ backgroundColor: "bg-secondary.main" }}>
          <Typography mb={3} component={"h4"} variant="heading-04">{props.info.heading}</Typography>
          <Typography mb={6} component={"p"} variant="body-02">{props.info.content}</Typography>
          <Button
            variant="contained"
            color="btn-primary"
            onClick={()=>setOpenBottomSheet(false)}
            fullWidth
          >
            <Typography variant="callout-02" p={5}>
              OK
            </Typography>
          </Button>
        </Box>
    </BottomSheet>
    }
    </>
  );
};

TextField.propTypes = TextFieldPropTypes;

export default TextField;
import "./Header.scss";
import PropTypes, { InferProps } from "prop-types";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "../../assets/chevron-left.svg";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { goBackHelper } from "../../utils/utils";

const HeaderPropTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  handleNavigation: PropTypes.func,
  path: PropTypes.string,
  styles: PropTypes.object,
}

const Header = (props: InferProps<typeof HeaderPropTypes>) => {
  const navigate = useNavigate()

  const handleClick = () => {
    goBackHelper(props, navigate)
  }

  return (
    <Box sx={{ mb: 6, textAlign: 'center', zIndex: 1, position: "relative", ...props.styles }} id="header">
      <IconButton onClick={handleClick} sx={{ position: "absolute", left: "0" }}><img src={props.icon!} alt="" /></IconButton>
      {
        props.text &&
        <Typography variant="callout-02">
          {props.text}
        </Typography>
      }
    </Box>
  );
};

Header.propTypes = HeaderPropTypes;

Header.defaultProps = {
  icon: ChevronLeft,
  styles: { pb: "24px" }
}

export default Header;

import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {getFeatureFlags} from "../../services/featureFlag";
import {getSnackbarArgs} from "../../utils/utils";
import useSnackbars from "../../context/Snackbar/Consumer";
import {TFeatureFlags} from "../../types/featureFlag";
import routes from "../../utils/routes";

const MaintenanceRoute = ({ maintenancePath = '/maintenance', children, currentPath }: any) => {
    const navigate = useNavigate();
    const { openSnackbar, closeSnackbar } = useSnackbars();
    const [featureFlags, setFeatureFlags] = useState<TFeatureFlags>({} as TFeatureFlags);

    useEffect(() => {
        (async () => {
            const getFeatureFlagsResponse = await getFeatureFlags()
            if (getFeatureFlagsResponse.error)
                openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "get-form-config" }))
            else {
                setFeatureFlags(getFeatureFlagsResponse.res);
            }
        })()
    }, [])

    useEffect(() => {
        if (featureFlags?.featureFlags?.maintenance?.enabled && currentPath !== routes.contactUs.path && currentPath !== routes.chat.path) {
            navigate(maintenancePath)
        }
    }, [featureFlags, currentPath]);

    return children
};

export default MaintenanceRoute;
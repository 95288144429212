import { Box, Button, Typography } from "@mui/material"
import Chat from "../../assets/chat.svg";
import { useNavigate } from "react-router-dom";
import routes from "../../utils/routes";
import { useTranslation } from "react-i18next";

const ChatNotAvailable = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      gap: 4,
      paddingBottom: "140px"
    }}>
        <img width="140px" src={Chat} alt="" />
        <Typography variant="heading-05" textAlign="center" marginTop={7}>Be right back!</Typography>
        <Typography variant="body-03" textAlign="center">There seems to be an unexpected error. Our team is currently looking into it. In the meantime, you can still submit an enquiry or visit our Help Centre.</Typography>
        <Box display='flex' flexDirection="column" gap={4} alignItems='stretch' width='100%' marginTop={9} 
          sx={{
            position: "absolute",
            right: 0,
            left: 0,
            bottom: 0,
        }}>
          <Button
            variant="contained"
            onClick={() => { navigate(routes.contactUs.path) }} 
            color="btn-primary"
            sx={{
              flex: 1,
            }}
          >
            <Typography variant="callout-02" p={5}>
            Submit an enquiry
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={() => { navigate(routes.getHelp.path) }} 
            sx={{
              borderColor: "#FAFAFA",
              '&:hover': {
                  borderColor: "#FAFAFA",
                  backgroundColor: "bg-secondary.main"
              },
              flex: 1
          }}
          >
            <Typography variant="callout-02" p={5}>
              Help Centre
            </Typography>
          </Button>
        </Box>
    </Box>
  )
}

export default ChatNotAvailable
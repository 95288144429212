import featureFlags from "../configs/features";

const scribe = window.scribesdk;

const preProcessorURL = process.env["REACT_APP_SCRIBE_PREPROCESSOR"] as string
const transmitterURL = process.env["REACT_APP_SCRIBE_TRANSMITTER"] as string
const scribeENV = process.env["REACT_APP_SCRIBE_ENV"] as string
let isProdENV = scribeENV === "prod" ? true : false

scribe?.initialize("help-center", isProdENV, false, "", preProcessorURL, transmitterURL)

export const sendScribeEvent = (eventName: string, facets={}) => {
    if(!featureFlags.scribe) return
    try {
        scribe.sendEvent(eventName, facets);
    } catch (error) {
        console.error('Error while sending scribe event');
    }
};
import { Box } from '@mui/material'
import './BaseCard.scss'

const BaseCard = (props: any) => {
    return (
        <Box
        className='border-radius-small'
            sx={{
                bgcolor: 'bg-secondary.main',
                height: '128px',
                position:'relative',
                padding: 5
            }}
        >
            {props.children}
        </Box>
    )
}

export default BaseCard

import {Link} from "react-router-dom";
import routes from "../../utils/routes";
import ListItem from "../ListItem/ListItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import send from "../../assets/send.svg";
import ChevronRight from "../../assets/chevron-right.svg";
import React, {useEffect, useMemo, useState} from "react";
import {sendScribeEvent} from "../../utils/scribeHelper";
import {getUserStatus, UserStatus} from "../../utils/getHelp";
import useSnackbars from "../../context/Snackbar/Consumer";
import {getSnackbarArgs} from "../../utils/utils";
import {Button} from "@mui/material";
import ActionSheet from "../ActionSheet/ActionSheet";
import Hazard from "../../assets/hazard.svg";
import {isClicked} from "../../utils/contactForm";
import {useTranslation} from "react-i18next";

const emailVerificationBottomSheet = 'email-verification-bottom-sheet'

interface ContactUsFormComponentPropTypes {
    status?: UserStatus
}

const ContactUsFormComponent = (props: ContactUsFormComponentPropTypes) => {
    const transactionIDStateData = window.history.state?.usr?.transactionID
    const emailVerificationLink = process.env["REACT_APP_EMAIL_VERIFICATION_LINK"] ?? ""
    const { openSnackbar, closeSnackbar } = useSnackbars()
    const [showEmailVerificationBottomSheet, setShowEmailVerificationBottomSheet] = useState(false)
    const { t } = useTranslation()

    const onContactUsClick = () => {
        sendScribeEvent("help_getmorehelp_email_tap")
        if (!props.status?.isEmailVerified) {
            setShowEmailVerificationBottomSheet(true)
        }
    }

    const verifyEmailPrimaryButton = useMemo(() => {
        return <Button
            variant="contained"
            onClick={() => {
                if (emailVerificationLink)
                    window.location.href = emailVerificationLink
                else
                    openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "verification-link-empty-error" }))
            }}
            sx={{
                backgroundColor: "brand-primary.main",
                '&:hover': {
                    backgroundColor: 'brand-primary.main'
                },
                flex: 1
            }}
        >
            <Typography variant="callout-02" p={5} sx={{ color: 'text-primary.main' }}>
                Verify now
            </Typography>
        </Button>
    }, [emailVerificationLink, openSnackbar, closeSnackbar])

    const verifyEmailSecondaryButton = useMemo(() => {
        return <Button
            variant="outlined"
            onClick={() => {
                setShowEmailVerificationBottomSheet(false)
            }}
            sx={{
                borderColor: "#FAFAFA",
                backgroundColor: "bg-secondary.main",
                '&:hover': {
                    borderColor: "#FAFAFA",
                    backgroundColor: "bg-secondary.main"
                },
                flex: 1
            }}
        >
            <Typography variant="callout-02" p={5} sx={{ color: 'text-primary.main' }}>
                Not now
            </Typography>
        </Button>
    }, [])

    return (
        <div>
        {props.status?.isOnboarded &&  props.status?.isFirstFundDone &&
        <Link
            to={props.status.isEmailVerified ? routes.contactUs.path : ""}
            state={{ transactionIDStateData }}
            className="no-text-decoration"
            onClick={onContactUsClick}
        >
            <ListItem
                primaryText={
                    <Typography variant="body-02" sx={{ color: "text-primary.main" }}>{ t("helpcentre_maintenance_submit_enquiry")}</Typography>
                }
                primaryIcon={
                    <IconButton><img src={send} alt="" /></IconButton>
                }
                secondaryIcon={
                    <IconButton><img src={ChevronRight} alt="" /></IconButton>
                }
                isCurved={false}
            />
        </Link>
        }
            <ActionSheet
                id={emailVerificationBottomSheet}
                icon={<img src={Hazard} alt="" />}
                open={showEmailVerificationBottomSheet}
                onClose={(e) => {
                    if (!isClicked(emailVerificationBottomSheet, e.target)) {
                        setShowEmailVerificationBottomSheet(false)
                    }
                }}
                title="Looks like your email is unverified"
                description="Please verify your email now before returning to submit your query."
                primaryButton={verifyEmailPrimaryButton}
                secondaryButton={verifyEmailSecondaryButton}
            />
        </div>
    );
};

export default ContactUsFormComponent;
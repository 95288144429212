import './SearchBar.scss';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useRef } from 'react';
import theme from '../../theme';
import PropTypes, { InferProps } from "prop-types";

const SearchBarPropTypes = {
  primaryIcon: PropTypes.string,
  secondaryIcon: PropTypes.string,
  autoFocus: PropTypes.bool,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

const SearchBar = (props: InferProps<typeof SearchBarPropTypes>) => {
  const inputBarRef = useRef<HTMLInputElement>()

  const handleClearIconClick = () => {
    props.onChange("")
    inputBarRef.current?.focus()
  }

  return (
    <Paper
      component="form"
      onSubmit={(e: any) => e.preventDefault()}
      classes={{ root: 'border-radius-maximum' }}
      sx={{ px: 5, py: '10px', display: 'flex', alignItems: 'center', backgroundColor: 'bg-tertiary.main', boxShadow: 'none' }}
    >
      {props.primaryIcon &&
        <IconButton sx={{ pr: 3 }}>
          <img src={props.primaryIcon} alt="SearchIcon" />
        </IconButton>
      }

      <Typography variant="body-03" width='100%'>
        <InputBase
          autoFocus={props.autoFocus ? true : false}
          fullWidth={true}
          sx={{ flex: 1, input: { color: 'text-primary.main', py: 0 }, 'input::placeholder': { color: 'text-disabled.main' }, caretColor: theme.palette["text-primary"].main }}
          placeholder={props.placeholder!}
          value={props.value || ''}
          onChange={(event: any) => props.onChange(event.target.value)}
          onFocus={() => props.onFocus!()}
          onBlur={() => props.onBlur!()}
          inputRef={inputBarRef}
        />
      </Typography>

      {props.secondaryIcon && props.value?.length > 0 &&
        <IconButton onClick={handleClearIconClick}>
          <img src={props.secondaryIcon} alt="ClearIcon" />
        </IconButton>
      }
    </Paper>
  );
}

SearchBar.propTypes = SearchBarPropTypes;

SearchBar.defaultProps = {
  placeholder: "",
  onFocus: () => {},
  onBlur: () => {},
}

export default SearchBar;
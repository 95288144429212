const feedBackOptions: Array<feedBackOption> = [
    {
        title: "GXS buddy was able to address my needs"
    },
    {
        title: "GXS buddy was knowledgable"
    }, 
    {
        title: "GXS buddy provided prompt responses"
    }, 
    {
        title: "Chat function was easy to use"
    },
]

type feedBackOption = {
    title: string,
    selected?: boolean
}

export { feedBackOptions }
export type { feedBackOption }

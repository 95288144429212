import "./ArticleListItem.scss";
import ListItem from "../../components/ListItem/ListItem";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const ArticleListItem = (props: any) => {
  return <ListItem
    primaryText={
      <Typography variant="body-02" sx={{ color: "text-primary.main" }}>{props.text}</Typography>
    }
    secondaryIcon={
      <IconButton><img src={props.icon} alt="" /></IconButton>
    }
    isCurved={props.isCurved}
  />
};

export default ArticleListItem;
import "./ArticleNotFound.scss";
import notFound from '../../assets/notFound.svg'
import { InferProps } from "prop-types";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import routes from "../../utils/routes";
import i18n from '../../i18n/config';

const ArticleNotFoundPropTypes = {}

const ArticleNotFound = (props: InferProps<typeof ArticleNotFoundPropTypes>) => {
  return (
    <div className="center-text-only-screens">
      <img src={notFound} alt="" />
      <Typography component="p" mt={5} variant="heading-05">
          {i18n.t("helpcentre_article_not_found_that_is_new")}
      </Typography>
      <Typography component="p" mt={3} variant="callout-03">
          {i18n.t("helpcentre_article_not_found_need_help")}
        <Link to={routes.getHelp.path} className='no-text-decoration'>
          <Box component="span" sx={{ color: "text-link.main" }}>{i18n.t("helpcentre_article_not_found_get_in_touch")}</Box>
        </Link>
      </Typography>
    </div>
  );
};

ArticleNotFound.propTypes = ArticleNotFoundPropTypes;

export default ArticleNotFound;

import "./RatingPage.scss";
import { InferProps } from "prop-types";
import CheckBoxItem from "../../components/CheckboxItem/CheckboxItem";
import CurvedListItemContainer from "../../components/CurvedListItemContainer/CurvedListItemContainer";
import { feedBackOptions } from "../../static-data-stubs/rating"
import type { feedBackOption } from "../../static-data-stubs/rating";

const RatingPagePropTypes = {}

const RatingPage = (props: InferProps<typeof RatingPagePropTypes>) => {
  const onCheckChange = (optionsObj: feedBackOption, value: boolean) => {
    optionsObj.selected = value
  }
  return (
    <div>
      <CurvedListItemContainer>
        {
          feedBackOptions.map((option, index) => {
            return (
              <CheckBoxItem key={"option" + index} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onCheckChange(option, e.target.checked) }} text={option.title} />
            )
          })
        }
      </CurvedListItemContainer >
    </div>
  );
};

RatingPage.propTypes = RatingPagePropTypes;

export default RatingPage;
